import { FALLBACK_ERROR_MESSAGE } from 'constants/index';
import api from '../helpers/axios';
import JUSPAY_API from 'helpers/juspayApi';

export function requestOtp(data) {
  return api.post('/api/customer/otp-generate', data);
}

export function loginUser(data) {
  return api.post('/api/customer/login', data);
}

export function fetchHomePageData(data) {
  return api.get('/api/customer/pages/home', { params: data });
}

export function fetchProducts(data, cancelToken) {
  const body = {
    params: { limit: 20, ...data },
  };
  if (cancelToken) {
    body.cancelToken = cancelToken;
  }
  return api.get('/api/products/2.0/fetch', body);
}

export function fetchProductsFromSearch(data, cancelToken) {
  const body = {
    params: data,
  };
  if (cancelToken) {
    body.cancelToken = cancelToken;
  }
  return api.get('/api/products/2.0/fetch-search', body);
}

export function fetchSearchAutoSuggest(data, cancelToken) {
  const body = {
    params: data,
  };
  if (cancelToken) {
    body.cancelToken = cancelToken;
  }
  return api.get('/api/search/suggests', body);
}

export function fetchCategoryByCategoryId(category_id, dealerParams) {
  return api.get(`/api/category/${category_id}`, { params: dealerParams });
}

export function addUpdateCart(data) {
  return api.post('/api/customer/cart', data);
}

export function fetchCart(data) {
  return api.get('/api/customer/cart', { params: data });
}

export function fetchWidgetById(widget_id, dealerParams) {
  return api.get(`/api/widget/${widget_id}`, { params: dealerParams });
}

export function fetchWidgetNavigationFeedById(widget_id, dealerParams) {
  return api.get(`/api/customer/navigation/widget/${widget_id}`, {
    params: dealerParams,
  });
}

export function fetchBannerByBannerId(banner_id, dealerParams) {
  return api.get(`/api/banner/${banner_id}`, { params: dealerParams });
}

export function cartOrdersConfirm(data) {
  return api.put('/api/customer/order/confirm_order', data);
}

export function cartOrdersPreOrder(data) {
  return api.post('/api/customer/order/pre_order', data);
}

export function fetchCustomerOrder(order_number) {
  return api.get(`/api/customer/order/${order_number}`);
}

export const deleteCustomerOrder = (payload) =>
  api.delete('/api/customer/order/cancel', { params: payload });

export function fetchOrderDetail(order_number) {
  return api.get(`/api/order/${order_number}`);
}

export function fetchCustomerDetail() {
  return api.get('/api/customer/details');
}

export function updateCustomerName(data) {
  return api.post('/api/customer/details', data);
}

export const getCustomerOrderJourney = (payload) =>
  api.get('/api/customer/order-journey', {
    params: payload,
  });

export const getCustomerOrderCancellationReasons = (payload) =>
  api.get('/api/customer/customer_order/cancellation_reasons', {
    params: payload,
  });

export const fetchCustomerLoginConfig = (params) =>
  api.get('/api/customer/config', {
    params,
  });

export const getProductAttributes = (payload) =>
  api.get(`/api/products/product_listing/${payload?.product_listing_id}`, {
    params: {
      dealerToken: payload?.dealerToken,
      customerId: payload?.customerId,
    },
  });

export const fetchCustomerMetric = (payload) =>
  api.get(`/api/internal/customer/metric`, {
    params: {
      ...payload,
    },
  });
export const assignPickupStore = (data) =>
  api.post('api/customer/pickupstore/assign', data);

export function fetchCustomerOrderRefundInfo(
  customer_order_number,
  customer_id
) {
  return api.get(
    `/api/customer/${customer_id}/refund/${customer_order_number}`
  );
}

export function updateCustomerOrderRefundInfo(
  customer_order_number,
  customer_id,
  orders
) {
  return api.put(
    `/api/customer/${customer_id}/refund/${customer_order_number}`,
    orders
  );
}

export function uploadRefundImageFile(image) {
  const formData = new FormData();
  formData.append('image', image);

  const opts = {
    transformRequest(data) {
      return data;
    },
  };
  return api.post('/api/customer/file/upload', formData, opts);
}

export const getXRupeeOffer = (data) =>
  api.get('/api/customer/app-offers', { params: data });

export const getCustomerOrderHistory = (payload) => {
  const { limit, cursor, isInitialCall = true } = payload;
  const url = isInitialCall
    ? `/api/customer/order-history?past_orders_limit=${limit}`
    : `/api/customer/order-history?past_orders_limit=${limit}&past_orders_cursor=${cursor}`;
  return api.get(url);
};

export const updateCustomerLiveLatLong = (payload) =>
  api.post(`/api/customer/live-lat-long`, payload);

export const handleError = (err) => {
  const error = err.response?.data?.errors || {
    message: FALLBACK_ERROR_MESSAGE,
  };
  return error;
};

export const fetchCustomerReferral = () => {
  return api.get('/api/customer/referral');
};

export const fetchCustomerReferralTemplate = () => {
  return api.post('/api/customer/referral-template');
};

export function fetchCustomerRealEstateWidget(data) {
  return api.get('/api/customer/customer-real-estate', { params: data });
}

export function fetchPaymentOptionsList(data) {
  return api.post('/api/customer/fetch-payment-options', data);
}

export function initializeJuspayTransaction(data) {
  return JUSPAY_API.post('/txns', data);
}

export function verifyUpiVpa(data) {
  return api.post('/api/customer/verify-upi-vpa', data);
}

export function paymentInitiate(data) {
  return api.post('/api/customer/order/payment/initiate', data);
}

export function pushClientError(data) {
  return api.post('/api/client-error', data);
}

export const fetchCustomerOrderReviews = (order_number) => {
  return api.get(`/api/customer/order/${order_number}/reviews`);
};

export const updateCustomerOrderReviews = (payload) => {
  return api.post('/api/customer/sub-order/review', payload);
};

export const fetchSubOrderReview = (sub_order_id) => {
  return api.get('/api/customer/sub-order/review', {
    params: { sub_order_id },
  });
};

export const reattemptOrder = (data) => {
  return api.post('/api/customer/order/notification/response', data);
};

export const fetchProductReviews = (payload) => {
  const { type, cursor } = payload;
  return api.get('/api/products/product_listing/reviews', {
    params: payload,
  });
};

export const upvoteReview = (payload) => {
  return api.post('/api/customer/review/helpful', payload);
};
