import { LOADING_TIME_EVENT_CONSTANTS } from 'constants/eventsConstant';
import { getFarmisoInitTimestamp } from 'mobileInterface/helpers/androidHelpers';
const loadTimeEventProperties: any = {};

export const getLoadTimestampPropertiesMixpanel = () => {
  const { initTime, meeshoLoaderStartTime, meeshoLoaderEndTime } =
    getFarmisoInitTimestamp();
  if (initTime > 0) {
    loadTimeEventProperties['Time From Webview Initialization'] =
      Date.now() - initTime;
  }
  if (meeshoLoaderEndTime > 0 && meeshoLoaderStartTime > 0) {
    loadTimeEventProperties['Meesho Loader Start To End'] =
      meeshoLoaderEndTime - meeshoLoaderStartTime;
  }
  if (meeshoLoaderStartTime > 0 && initTime > 0) {
    loadTimeEventProperties['Webview Init To Meesho Loader Start'] =
      meeshoLoaderStartTime - initTime;
  }
  if (
    loadTimeEventProperties[
      LOADING_TIME_EVENT_CONSTANTS.index_tsx_file_load_time
    ] > 0 &&
    loadTimeEventProperties[
      LOADING_TIME_EVENT_CONSTANTS.index_html_file_load_time
    ] > 0
  ) {
    loadTimeEventProperties['Main.js Load Time'] =
      loadTimeEventProperties[
        LOADING_TIME_EVENT_CONSTANTS.index_tsx_file_load_time
      ] -
      loadTimeEventProperties[
        LOADING_TIME_EVENT_CONSTANTS.index_html_file_load_time
      ];
  }
  if (
    loadTimeEventProperties[LOADING_TIME_EVENT_CONSTANTS.launch_app_time] > 0 &&
    loadTimeEventProperties[
      LOADING_TIME_EVENT_CONSTANTS.index_tsx_file_load_time
    ] > 0
  ) {
    loadTimeEventProperties['Main.js Load To Launch App'] =
      loadTimeEventProperties[LOADING_TIME_EVENT_CONSTANTS.launch_app_time] -
      loadTimeEventProperties[
        LOADING_TIME_EVENT_CONSTANTS.index_tsx_file_load_time
      ];
  }
  if (
    loadTimeEventProperties[
      LOADING_TIME_EVENT_CONSTANTS.config_api_call_start
    ] > 0 &&
    loadTimeEventProperties[LOADING_TIME_EVENT_CONSTANTS.launch_app_time] > 0
  ) {
    loadTimeEventProperties['Launch App To Config API Start'] =
      loadTimeEventProperties[
        LOADING_TIME_EVENT_CONSTANTS.config_api_call_start
      ] - loadTimeEventProperties[LOADING_TIME_EVENT_CONSTANTS.launch_app_time];
  }
  if (
    loadTimeEventProperties[LOADING_TIME_EVENT_CONSTANTS.config_api_call_end] >
      0 &&
    loadTimeEventProperties[
      LOADING_TIME_EVENT_CONSTANTS.config_api_call_start
    ] > 0
  ) {
    loadTimeEventProperties['Config API Response Time'] =
      loadTimeEventProperties[
        LOADING_TIME_EVENT_CONSTANTS.config_api_call_end
      ] -
      loadTimeEventProperties[
        LOADING_TIME_EVENT_CONSTANTS.config_api_call_start
      ];
  }

  if (
    loadTimeEventProperties[
      LOADING_TIME_EVENT_CONSTANTS.index_html_file_load_start_time
    ] > 0 &&
    loadTimeEventProperties[
      LOADING_TIME_EVENT_CONSTANTS.index_html_file_load_end_time
    ] > 0
  ) {
    loadTimeEventProperties['index HTML Loading Time'] =
      loadTimeEventProperties[
        LOADING_TIME_EVENT_CONSTANTS.index_html_file_load_end_time
      ] -
      loadTimeEventProperties[
        LOADING_TIME_EVENT_CONSTANTS.index_html_file_load_start_time
      ];
  }
  if (
    loadTimeEventProperties[
      LOADING_TIME_EVENT_CONSTANTS.main_js_file_load_end_time
    ] > 0 &&
    loadTimeEventProperties[
      LOADING_TIME_EVENT_CONSTANTS.main_js_file_load_start_time
    ] > 0
  ) {
    loadTimeEventProperties['Main.js File Loading Time'] =
      loadTimeEventProperties[
        LOADING_TIME_EVENT_CONSTANTS.main_js_file_load_end_time
      ] -
      loadTimeEventProperties[
        LOADING_TIME_EVENT_CONSTANTS.main_js_file_load_start_time
      ];
  }
  if (
    loadTimeEventProperties[
      LOADING_TIME_EVENT_CONSTANTS.mainComp_js_file_load_end_time
    ] > 0 &&
    loadTimeEventProperties[
      LOADING_TIME_EVENT_CONSTANTS.mainComp_js_file_load_start_time
    ] > 0
  ) {
    loadTimeEventProperties['MainComp.js File Loading Time'] =
      loadTimeEventProperties[
        LOADING_TIME_EVENT_CONSTANTS.mainComp_js_file_load_end_time
      ] -
      loadTimeEventProperties[
        LOADING_TIME_EVENT_CONSTANTS.mainComp_js_file_load_start_time
      ];
  }
  return loadTimeEventProperties;
};

export const collectLoadTimeEventProperties = (key, value) => {
  if (!loadTimeEventProperties[key]) {
    loadTimeEventProperties[key] = value;
  }
};
