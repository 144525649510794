import { PLOTLINE_SDK_URL, PLOTLINE_API_KEY } from 'config';
import { getBackToMeeshoUrl } from 'mobileInterface/helpers/androidHelpers';
import { getLocalStorage } from 'utils';
import { useCommonStore } from 'reducers/useCommonStore';

const PLOTLINE_STUDY_SHOWN = 'Plotline Study Shown';
const PLOTLINE_STUDY_ENDED = 'Plotline Study Ended';

function exitSuperstore() {
  window.location.href = getBackToMeeshoUrl();
}

export const loadPlotline = () => {
  if (window.plotline) return;
  window._plotQueue = window._plotQueue || [];
  window.plotline = function () {
    window._plotQueue.push(arguments);
  };
  const a = document.createElement('script');
  a.async = 1;
  a.src = PLOTLINE_SDK_URL;
  const b = document.getElementsByTagName('script')[0];
  b.parentNode.insertBefore(a, b);
};

export const initPlotline = (user_info) => {
  if (user_info?.customer_id && window.plotline) {
    window.plotline('init', PLOTLINE_API_KEY, user_info?.customer_id);
    window.plotline('setPlotlineEventsListener', (eventName, properties) => {
      const exitIntentStudyId =
        user_info?.ux_ab_experiments?.exit_intent_plotline_study_id ?? null;
      if (
        eventName === PLOTLINE_STUDY_SHOWN &&
        properties?.studyId === exitIntentStudyId
      ) {
        return useCommonStore.getState().setShowExitTimeoutLoader(false);
      }

      if (
        eventName === PLOTLINE_STUDY_ENDED &&
        properties?.studyId === exitIntentStudyId
      ) {
        return exitSuperstore();
      }
    });
  }
};

export const plotlineSetColor = () => {
  const color = {
    overlay: '#00000055',
    background: '#ffffff',
    title: '#000000',
    description: '#00000080',
    optionText: '#000000',
    optionBackground: '#03000000',
    optionBorder: '#e6e6e6',
    buttonBackground: '#0aad00',
    buttonText: '#ffffff',
    progressValue: '#0aad00',
    progressBackground: '#00000030',
    border: '#000000', // only for Desktop
  };
  if (window.plotline) {
    window.plotline('setColor', color);
  }
};

export const plotlineSetFont = () => {
  if (window.plotline) {
    window.plotline('setFont', 'Times New Roman');
  }
};

export const plotlineEventsTrack = (event_name) => {
  const user_info = getLocalStorage('user') ?? '{}';
  let eventProperties = {};
  if (JSON.parse(user_info)?.latest_consignment_number) {
    eventProperties = {
      consignmentId: JSON.parse(user_info)?.latest_consignment_number,
    };
  }
  if (window.plotline) {
    window.plotline('track', event_name, eventProperties);
  }
};
