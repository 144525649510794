import { LOADING_TIME_EVENT_CONSTANTS } from 'constants/eventsConstant';
import { collectLoadTimeEventProperties } from 'utils/loadTimeDataUtils';

const usePerformanceTracker = () => {
  try {
    const ORIGIN_TIME = window.performance.timeOrigin;
    window.addEventListener('load', () => {
      const entries = window.performance.getEntriesByType('navigation');
      entries?.slice(0, 1)?.forEach((entry) => {
        collectLoadTimeEventProperties(
          LOADING_TIME_EVENT_CONSTANTS.index_html_file_load_start_time,
          Math.floor(ORIGIN_TIME + entry.startTime)
        );
        collectLoadTimeEventProperties(
          LOADING_TIME_EVENT_CONSTANTS.index_html_file_load_end_time,
          Math.floor(ORIGIN_TIME + entry.responseEnd)
        );
      });

      const resourceEntries = window.performance.getEntriesByType('resource');
      resourceEntries.forEach((resource) => {
        if (resource.name.endsWith('.js')) {
          if (resource.name.includes('main')) {
            collectLoadTimeEventProperties(
              LOADING_TIME_EVENT_CONSTANTS.main_js_file_load_start_time,
              Math.floor(ORIGIN_TIME + resource.startTime)
            );
            collectLoadTimeEventProperties(
              LOADING_TIME_EVENT_CONSTANTS.main_js_file_load_end_time,
              Math.floor(ORIGIN_TIME + resource.responseEnd)
            );
          }
          if (resource.name.includes('MainComp')) {
            collectLoadTimeEventProperties(
              LOADING_TIME_EVENT_CONSTANTS.mainComp_js_file_load_start_time,
              Math.floor(ORIGIN_TIME + resource.startTime)
            );
            collectLoadTimeEventProperties(
              LOADING_TIME_EVENT_CONSTANTS.mainComp_js_file_load_end_time,
              Math.floor(ORIGIN_TIME + resource.responseEnd)
            );
          }
        }
      });
      if ('performance' in window) {
        const resourceEntries = performance.getEntriesByType('navigation');
        let custom_ttfb = null;
        resourceEntries.forEach((resource) => {
          custom_ttfb = Math.floor(ORIGIN_TIME + resource.responseStart);
        });
        collectLoadTimeEventProperties(
          LOADING_TIME_EVENT_CONSTANTS.custom_ttfb_time,
          custom_ttfb
        );
      }

      if ('PerformanceObserver' in window) {
        const observer = new PerformanceObserver((entryList) => {
          let custom_fcp = null;
          entryList.getEntries().forEach((entry) => {
            if (entry.name === 'first-contentful-paint') {
              custom_fcp = Math.floor(ORIGIN_TIME + entry.startTime);
            }
          });
          collectLoadTimeEventProperties(
            LOADING_TIME_EVENT_CONSTANTS.custom_fcp_time,
            custom_fcp
          );
        });
        observer.observe({ type: 'paint', buffered: true });
      }

      if ('PerformanceObserver' in window) {
        const observer = new PerformanceObserver((entryList) => {
          let custom_lcp = null;
          entryList.getEntries().forEach((entry) => {
            if (entry.entryType === 'largest-contentful-paint') {
              custom_lcp = Math.floor(ORIGIN_TIME + entry.startTime);
            }
          });
          collectLoadTimeEventProperties(
            LOADING_TIME_EVENT_CONSTANTS.custom_lcp_time,
            custom_lcp
          );
        });
        observer.observe({ type: 'largest-contentful-paint', buffered: true });
      }
    });
  } catch (error) {}
};

export default usePerformanceTracker;
