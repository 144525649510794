import { isAndroidUserAgent } from './androidHelpers';
import {
  getCheckLocationPermissionAPI,
  isIphoneUserAgent,
} from './iPhoneHelpers';
import { XO_TOKEN } from 'constants/index';
import { useCommonStore } from 'reducers/useCommonStore';

export const isWebviewEnvironmentMode = () => {
  return isAndroidUserAgent() || isIphoneUserAgent();
};

export const getPlatformType = () => {
  if (isAndroidUserAgent()) {
    return 'Android';
  } else if (isIphoneUserAgent()) {
    return 'ios';
  } else {
    return 'web';
  }
};

export const hasReferralInterfaceEnabledInDevice = () => {
  if (isAndroidUserAgent() && window?.farmiso?.shareReferralCode) {
    return true;
  } else if (isIphoneUserAgent()) {
    return false;
  }
  return false;
};

export const checkGpsEnabledInDevice = () => {
  try {
    if (isAndroidUserAgent() && window?.farmiso?.checkGpsEnabled) {
      return window?.farmiso?.checkGpsEnabled?.() ?? false;
    } else if (isIphoneUserAgent()) {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

export const requestGpsProvidedInDevice = () => {
  if (isAndroidUserAgent() && window?.farmiso?.requestGps) {
    return true;
  } else if (isIphoneUserAgent()) {
    return true;
  }
  return false;
};

export const requestGpsInDevice = async () => {
  try {
    if (isAndroidUserAgent() && window?.farmiso?.requestGps) {
      await window?.farmiso?.requestGps?.();
    } else if (isIphoneUserAgent()) {
      useCommonStore.getState().setTriggerRequestUserLocationPermission(true);
    }
  } catch (e) {}
};

export const checkLocationPermissionInDevice = async () => {
  let locationPermissionInDevice = 'false';
  try {
    if (isAndroidUserAgent() && window?.farmiso?.checkLocationPermission) {
      locationPermissionInDevice =
        await window?.farmiso?.checkLocationPermission?.();
    } else if (isIphoneUserAgent()) {
      const permission = await getCheckLocationPermissionAPI();
      if (permission) {
        locationPermissionInDevice = 'true';
      }
    }
    return locationPermissionInDevice;
  } catch (e) {
    return locationPermissionInDevice;
  }
};

export const requestLocationPermissionProvidedInDevice = () => {
  if (isAndroidUserAgent() && window?.farmiso?.requestLocationPermission) {
    return true;
  } else if (
    isIphoneUserAgent() &&
    window?.webkit?.messageHandlers?.requestLocationPermission
  ) {
    return true;
  }
  return false;
};

export const requestLocationPermissionInDevice = () => {
  try {
    if (isAndroidUserAgent() && window?.farmiso?.requestLocationPermission) {
      window?.farmiso?.requestLocationPermission?.();
    } else if (
      isIphoneUserAgent() &&
      Boolean(window?.webkit?.messageHandlers?.requestLocationPermission)
    ) {
      window?.webkit?.messageHandlers?.requestLocationPermission?.postMessage?.(
        {}
      );
    }
  } catch (e) {}
};

export const xooxInjectorFromURL = async () => {
  // this is for dev environment to get the authenication tokens from URL
  const urlParams = new URLSearchParams(window.location.search);
  const xo = urlParams.get('xo');
  const appUserId = urlParams.get('app-user-id');
  if (Boolean(xo) && Boolean(appUserId)) {
    const xooxObj = {
      Xo: xo,
      'APP-USER-ID': appUserId,
    };
    window.xoox = {
      getParams: () => {
        return JSON.stringify(xooxObj);
      },
      refreshXo: () => {
        return JSON.stringify(xooxObj);
      },
    };
  }
};
