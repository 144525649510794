import { PAYMENT } from 'constants/actions';
import { fetchPaymentOptionsList } from './apiCalls';
import { useAuthStore } from 'reducers/useAuthStore';

export function setAvailableUpiAppsFromAndroid(payload) {
  return {
    type: PAYMENT.SET_AVAILABLE_UPI_APPS_FROM_ANDROID,
    payload,
  };
}

export function setAvailablePaymentOptions(payload) {
  return {
    type: PAYMENT.SET_AVAILABLE_PAYMENT_OPTIONS,
    payload,
  };
}

export function setAvailablePaymentOptionsLoading(payload) {
  return {
    type: PAYMENT.SET_AVAILABLE_PAYMENT_OPTIONS_LOADING,
    payload,
  };
}

export function setAvailablePaymentOptionsError(payload) {
  return {
    type: PAYMENT.SET_AVAILABLE_PAYMENT_OPTIONS_ERROR,
    payload,
  };
}

export function setUpiIntentFlowResponseInRedux(payload) {
  return {
    type: PAYMENT.SET_UPI_INTENT_FLOW_RESPONSE,
    payload,
  };
}

// thunks
export function getPaymentOptionsList(payload) {
  return async (dispatch) => {
    dispatch(setAvailablePaymentOptionsLoading(true));
    const { config } = useAuthStore.getState();
    let user_id;
    if (config?.user_info?.user_id) {
      user_id = config?.user_info?.user_id;
    }
    try {
      const requestPayload = {
        checkout_identifier: 'default',
        available_upi_apps: payload,
        skip_bnpl_eligibility: true,
        user_id,
      };
      const { data } = await fetchPaymentOptionsList(requestPayload);
      dispatch(setAvailablePaymentOptions(data));
      dispatch(setAvailablePaymentOptionsLoading(false));
      return true;
    } catch (e) {
      dispatch(
        setAvailablePaymentOptionsError({
          status: true,
          msg: JSON.stringify(e.response?.data?.errors),
        })
      );
      dispatch(setAvailablePaymentOptionsLoading(false));
      return false;
    }
  };
}
