import { getSessionStorage } from 'utils';
import {
  BACK_TO_MEESHO_URL_FROM_FRAGMENT,
  BACK_TO_MEESHO_URL,
  MEESHO_ANDROID_IOS_HOME_PAGE_LINK,
} from 'config';
import { IS_SOURCE_WEBVIEW_FRAGMENT, XO_TOKEN } from 'constants/index';
import { isIphoneUserAgent } from './iPhoneHelpers';
import { setItemInLS } from 'utils/memoryUtils';

export function getAndroidAppVersionCode(): number | null {
  if (
    typeof window === 'undefined' ||
    !window.xoox ||
    typeof window.xoox.getParams !== 'function'
  ) {
    return null;
  }

  let xooxObj;
  try {
    xooxObj = JSON.parse(window.xoox.getParams() ?? '{}');
  } catch (error) {
    return null;
  }

  const appVersionCode = xooxObj['App-Version-Code'];
  if (typeof appVersionCode !== 'string' || isNaN(Number(appVersionCode))) {
    return null;
  }

  return parseInt(appVersionCode, 10);
}

export const isAndroidUserAgent = (): boolean => {
  try {
    return (
      window &&
      window?.hasOwnProperty('xoox') &&
      typeof window?.xoox?.getParams === 'function'
    );
  } catch (error) {
    return false;
  }
};

export const getAndroidAuthInfo = () =>
  new Promise((resolve, reject) => {
    try {
      const tokenResponse = JSON.parse(window?.xoox?.getParams?.() ?? '{}');
      setItemInLS({ [XO_TOKEN]: JSON.stringify(tokenResponse) });
      resolve({ ...tokenResponse });
    } catch (err) {
      reject(null);
    }
  });

export const getAndroidRefreshedAuthInfo = () =>
  new Promise((resolve, reject) => {
    try {
      const tokenResponse = JSON.parse(window?.xoox?.refreshXo?.());
      resolve(tokenResponse);
    } catch (err) {
      reject(null);
    }
  });

export const handleAndroidLogin = () => {
  window?.farmiso?.onLoginLaunch?.();
};

export function getBackToMeeshoUrl(): string {
  if (isAndroidUserAgent()) {
    const isSourceWebviewFragment =
      getSessionStorage(IS_SOURCE_WEBVIEW_FRAGMENT) === 'true';

    if (isSourceWebviewFragment) {
      return BACK_TO_MEESHO_URL_FROM_FRAGMENT;
    } else {
      return BACK_TO_MEESHO_URL;
    }
  } else if (isIphoneUserAgent()) {
    return MEESHO_ANDROID_IOS_HOME_PAGE_LINK;
  }
  return BACK_TO_MEESHO_URL_FROM_FRAGMENT;
}

export function getAppVersion() {
  if (
    window &&
    Boolean(window.xoox) &&
    typeof window.xoox.getParams === 'function'
  ) {
    const xooxObj = JSON.parse(window?.xoox?.getParams?.() ?? '{}');
    return xooxObj?.['App-Version'];
  } else {
    return null;
  }
}

let webMetricsData = {};
let isWebMetricsFlushed = false;
export const collectWebMetricsData = (key, value) => {
  if (!webMetricsData[key]) {
    webMetricsData[key] = value;
  }
};
export const flushWebMetricsData = () => {
  if (!isWebMetricsFlushed) {
    isWebMetricsFlushed = true;
    if (
      isAndroidUserAgent() &&
      window?.webMetrics?.pushWebMetrics &&
      typeof window?.webMetrics?.pushWebMetrics === 'function'
    ) {
      window?.webMetrics?.pushWebMetrics?.(JSON.stringify(webMetricsData));
    }
  }
};

type TFarmisoInitTimestampBridge = {
  initTime?: number;
  meeshoLoaderStartTime?: number;
  meeshoLoaderEndTime?: number;
};

export const getFarmisoInitTimestamp = (): TFarmisoInitTimestampBridge => {
  let farmisoInitTimestamp = {
    initTime: 0,
    meeshoLoaderStartTime: 0,
    meeshoLoaderEndTime: 0,
  };
  try {
    if (
      isAndroidUserAgent() &&
      window?.farmiso?.getInitTimestamp &&
      typeof window.farmiso.getInitTimestamp === 'function'
    ) {
      const bridgeTimestampValues = JSON.parse(
        window.farmiso.getInitTimestamp()
      );
      farmisoInitTimestamp = {
        initTime: Number(bridgeTimestampValues.initTime ?? 0),
        meeshoLoaderStartTime: Number(
          bridgeTimestampValues.meeshoLoaderStartTime ?? 0
        ),
        meeshoLoaderEndTime: Number(
          bridgeTimestampValues.meeshoLoaderEndTime ?? 0
        ),
      };
    }
  } catch (error) {}
  return farmisoInitTimestamp;
};
