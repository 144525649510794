import {
  onFCP,
  onLCP,
  onFID,
  onINP,
  onCLS,
  onTTFB,
} from 'web-vitals/attribution';
import { logEventInMeeshoMixpanel, mixPanelEventData } from './mixpanel';
import { getCurrentPageName } from 'utils/pageUtils';
import routeConfig from 'routeConfig';
import { MEESHO_GROCERY_WEB_VITALS } from 'constants/eventsConstant';
import {
  collectWebMetricsData,
  flushWebMetricsData,
} from 'mobileInterface/helpers/androidHelpers';

function reportCallback(report, currentPageName) {
  try {
    const reportName = report.name;
    collectWebMetricsData(report.name, report.value);
    const web_vitals_event_data: {
      [key: string]: any;
    } = {
      value: report.value,
      rating: report.rating,
      name: reportName,
    };
    if (reportName === 'TTFB') {
      web_vitals_event_data.waitingTime = report?.attribution?.waitingTime;
      web_vitals_event_data.dnsTime = report?.attribution?.dnsTime;
      web_vitals_event_data.connectionTime =
        report?.attribution?.connectionTime;
      web_vitals_event_data.requestTime = report?.attribution?.requestTime;
      collectWebMetricsData(
        'TTFB_waitingTime',
        report?.attribution?.waitingTime
      );
      collectWebMetricsData('TTFB_dnsTime', report?.attribution?.dnsTime);
      collectWebMetricsData(
        'TTFB_connectionTime',
        report?.attribution?.connectionTime
      );
    } else if (reportName === 'FCP') {
      web_vitals_event_data.timeToFirstByte =
        report?.attribution?.timeToFirstByte;
      web_vitals_event_data.firstByteToFCP =
        report?.attribution?.firstByteToFCP;
      web_vitals_event_data.loadState = report?.attribution?.loadState;
      collectWebMetricsData(
        'FCP_timeToFirstByte',
        report?.attribution?.timeToFirstByte
      );
      collectWebMetricsData(
        'FCP_firstByteToFCP',
        report?.attribution?.firstByteToFCP
      );
    } else if (reportName === 'LCP') {
      web_vitals_event_data.element = report?.attribution?.element;
      web_vitals_event_data.lcpUrl = report?.attribution?.url;
      web_vitals_event_data.timeToFirstByte =
        report?.attribution?.timeToFirstByte;
      web_vitals_event_data.resourceLoadDelay =
        report?.attribution?.resourceLoadDelay;
      web_vitals_event_data.resourceLoadTime =
        report?.attribution?.resourceLoadTime;
      web_vitals_event_data.elementRenderDelay =
        report?.attribution?.elementRenderDelay;
      collectWebMetricsData(
        'LCP_timeToFirstByte',
        report?.attribution?.timeToFirstByte
      );

      collectWebMetricsData(
        'LCP_resourceLoadDelay',
        report?.attribution?.resourceLoadDelay
      );
      collectWebMetricsData(
        'LCP_resourceLoadTime',
        report?.attribution?.resourceLoadTime
      );
      collectWebMetricsData(
        'LCP_elementRenderDelay',
        report?.attribution?.elementRenderDelay
      );
      flushWebMetricsData();
    }
    mixPanelEventData({
      event_name: 'Web Vitals',
      event_data: {
        ...web_vitals_event_data,
        currentPageName,
      },
    });
    logEventInMeeshoMixpanel(MEESHO_GROCERY_WEB_VITALS, {
      ...web_vitals_event_data,
      currentPageName,
    });
  } catch (e) {}
}

export function collectWebVitalMetrics() {
  const currentPageName = getCurrentPageName({
    currentPathName: window.location?.pathname ?? '',
    routeConfig,
  });
  onTTFB((report) => reportCallback(report, currentPageName));
  onFCP((report) => reportCallback(report, currentPageName));
  onLCP((report) => reportCallback(report, currentPageName));
  onCLS((report) => reportCallback(report, currentPageName));
  onFID((report) => reportCallback(report, currentPageName));
  onINP((report) => reportCallback(report, currentPageName));
}
