import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './ErrorFallback';
import { extractErrorDetails } from './helper';
import { pushClientError } from 'actions/apiCalls';
import debounce from 'utils/debounce';
import { mixPanelEventData } from 'javascript/mixpanel';

window.onerror = debounce(async function (
  event,
  source,
  lineno,
  colno,
  errorStack
) {
  if (errorStack instanceof Error) {
    const errorDetails = extractErrorDetails(errorStack);
    if (errorDetails) {
      try {
        await pushClientError(errorDetails);
        mixPanelEventData({
          event_name: 'App Error Reported',
          event_data: {
            error_class: 'Runtime Window onError',
            error_origin: 'Client',
            error_message: errorDetails?.errorMessage,
          },
        });
      } catch (e) {}
    }
  }
},
100);

window.onunhandledrejection = debounce(async function (event) {
  if (event.reason instanceof Error) {
    const errorDetails = extractErrorDetails(event.reason);
    if (errorDetails) {
      try {
        await pushClientError(errorDetails);
        mixPanelEventData({
          event_name: 'App Error Reported',
          event_data: {
            error_class: 'Unhandled Rejection Error',
            error_origin: 'Client',
            error_message: errorDetails?.errorMessage,
          },
        });
      } catch (e) {}
    }
  }
}, 100);

function ErrorObserver({ children }) {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
  );
}

export default ErrorObserver;
