import { create } from 'zustand';

interface ICommonStore {
  hasSuccessFull: boolean;
  isHomepagePrefetched: boolean;
  isAppOffersPrefetched: boolean;
  voiceSearchResponseText: string | null;
  fodAbusePopupVisible: boolean | null;
  hasPlotlineExitIntentTrigger: boolean;
  showExitTimeoutLoader: boolean;
  triggerRequestUserLocationPermission: boolean;
  isNddSplashScreenShownInSession: boolean;
  isNddHeaderAnimationShownInSession: boolean;
  showAppUpdateModal: boolean;
  dsrBannerShown: boolean;
  fourProductHighlighterShimmer: boolean;
  setHasSuccessFull: (payload: boolean) => void;
  setIsHomepagePrefetched: (payload: boolean) => void;
  setIsAppOffersPrefetched: (payload: boolean) => void;
  setVoiceSearchResponseText: (payload: string | null) => void;
  setFodAbusePopupVisible: (payload: boolean | null) => void;
  setHasPlotlineExitIntentTrigger: (payload: boolean) => void;
  setShowExitTimeoutLoader: (payload: boolean) => void;
  setTriggerRequestUserLocationPermission: (payload: boolean) => void;
  setIsNddSplashScreenShownInSession: (payload: boolean) => void;
  setIsNddHeaderAnimationShownInSession: (payload: boolean) => void;
  setShowAppUpdateModal: (payload: boolean) => void;
  setDsrBannerShown: (payload: boolean) => void;
  setFourProductHighlighterShimmer: (payload: boolean) => void;
}

export const useCommonStore = create<ICommonStore>((set) => ({
  hasSuccessFull: false,
  isHomepagePrefetched: false,
  isAppOffersPrefetched: false,
  voiceSearchResponseText: null,
  fodAbusePopupVisible: null,
  hasPlotlineExitIntentTrigger: false,
  showExitTimeoutLoader: false,
  triggerRequestUserLocationPermission: false,
  isNddSplashScreenShownInSession: false,
  isNddHeaderAnimationShownInSession: false,
  showAppUpdateModal: false,
  dsrBannerShown: false,
  fourProductHighlighterShimmer: false,

  setHasSuccessFull: (payload) => set(() => ({ hasSuccessFull: payload })),
  setIsHomepagePrefetched: (payload) =>
    set(() => ({ isHomepagePrefetched: payload })),
  setIsAppOffersPrefetched: (payload) =>
    set(() => ({ isAppOffersPrefetched: payload })),
  setVoiceSearchResponseText: (payload) =>
    set(() => ({ voiceSearchResponseText: payload })),
  setFodAbusePopupVisible: (payload) =>
    set(() => ({ fodAbusePopupVisible: payload })),
  setHasPlotlineExitIntentTrigger: (payload) =>
    set(() => ({ hasPlotlineExitIntentTrigger: payload })),
  setShowExitTimeoutLoader: (payload) =>
    set(() => ({ showExitTimeoutLoader: payload })),
  setTriggerRequestUserLocationPermission: (payload) =>
    set(() => ({ triggerRequestUserLocationPermission: payload })),
  setIsNddSplashScreenShownInSession: (payload) =>
    set(() => ({ isNddSplashScreenShownInSession: payload })),
  setIsNddHeaderAnimationShownInSession: (payload) =>
    set(() => ({ isNddHeaderAnimationShownInSession: payload })),
  setShowAppUpdateModal: (payload) =>
    set(() => ({ showAppUpdateModal: payload })),
  setDsrBannerShown: (payload) => set(() => ({ dsrBannerShown: payload })),
  setFourProductHighlighterShimmer: (payload) =>
    set(() => ({ fourProductHighlighterShimmer: payload })),
}));
