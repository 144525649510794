export { pluralize } from './stringUtils';

export * from './asyncUtils';
export function setLocalStorage(key, value) {
  try {
    return localStorage.setItem(key, value);
  } catch (error) {
    return null;
  }
}

export function getLocalStorage(key) {
  try {
    return localStorage.getItem(key);
  } catch (error) {
    return null;
  }
}

export function removeLocalStorage(key) {
  try {
    return localStorage.removeItem(key);
  } catch (error) {
    return null;
  }
}

export function clearLocalStorage() {
  try {
    return localStorage.clear();
  } catch (error) {
    return null;
  }
}

export function setSessionStorage(key, value) {
  try {
    return sessionStorage.setItem(key, value);
  } catch (error) {
    return null;
  }
}

export function getSessionStorage(key) {
  try {
    return sessionStorage.getItem(key);
  } catch (error) {
    return null;
  }
}

export function removeSessionStorage(key) {
  try {
    return sessionStorage.removeItem(key);
  } catch (error) {
    return null;
  }
}
export const convertMeterToKilometerAboveThreshold = (meters) =>
  meters >= 1000 ? `${(meters / 1000).toFixed(1)} km` : `${meters} meters`;

export const convertMeterToKilometerV2 = (meters) =>
  meters >= 1000 ? `${(meters / 1000).toFixed(1)} km` : `${meters} m`;

export const isSessionStorageSupported = () => {
  try {
    const storage = window.sessionStorage;
    storage.setItem('test', 'test');
    storage.removeItem('test');
    return true;
  } catch (e) {
    return false;
  }
};
