export const close_icon_grey =
  'https://images.meesho.com/superstore/svg_images/close_icon_grey-i8zlro2qfajkxwrq.svg';
export const cheers_confetti =
  'https://images.meesho.com/superstore/svg_images/cheers_confetti-tngv6172rb1pc862.svg';
export const label_right_arrow_orange =
  'https://images.meesho.com/superstore/svg_images/label_right_arrow_orange-cnd9vayqgbcw7uxl.svg';
export const percentageLogo =
  'https://images.meesho.com/superstore/svg_images/percentageLogo-ny70ewlvjwqe3yyx.svg';
export const back_chevron =
  'https://images.meesho.com/superstore/svg_images/back-chevron-ae6bh6xesw33mpit.svg';
export const step_arrow =
  'https://images.meesho.com/superstore/svg_images/step_arrow-7woyfqh2p2cehh7b.svg';
export const privacy_icon =
  'https://images.meesho.com/superstore/svg_images/privacy-cf32xq7f59ssgn2x.svg';
export const toggle_icon =
  'https://images.meesho.com/superstore/svg_images/toggle-k29bax7hqlpurup8.svg';
export const settings_icon =
  'https://images.meesho.com/superstore/svg_images/settings-xf9u1ovs4tuzrnfq.svg';
export const location_icon =
  'https://images.meesho.com/superstore/svg_images/location-opl0nxbqzq7156sf.svg';
export const search_green =
  'https://images.meesho.com/superstore/svg_images/search-green-4olwzq8e82i25bkj.svg';
export const geo_logo =
  'https://images.meesho.com/superstore/svg_images/geo-logo-zhw5s9zg4loyyjt4.svg';
export const fo_close_outline =
  'https://images.meesho.com/superstore/svg_images/fo_close_outline-rv23mvofz5i691aw.svg';
export const info_small =
  'https://images.meesho.com/superstore/svg_images/info_small-g3vbio3y2hx5v2y8.svg';
export const thunder_bolt_blue =
  'https://images.meesho.com/superstore/svg_images/thunder_bolt_blue-zwf5vlc1iyvh57t2.svg';
export const chevron_right_white_bg =
  'https://images.meesho.com/superstore/svg_images/chevron-right-white-bg-k30mz3loc9lltmjr.svg';
export const delivery_truck_with_timer =
  'https://images.meesho.com/superstore/svg_images/delivery_truck_with_timer-5wzoybqb61kw3gq1.svg';
export const cart_black =
  'https://images.meesho.com/superstore/svg_images/cart_black-1h6hckwa3xfx7v07.svg';
export const cart_not_found =
  'https://images.meesho.com/superstore/svg_images/cart_not_found-y7aoro5h5y6vj01n.svg';
export const whatsapp_white =
  'https://images.meesho.com/superstore/svg_images/whatsapp_white-nah3e0eno4mo0e8w.svg';
export const left_arrow_black =
  'https://images.meesho.com/superstore/svg_images/left_arrow_black-8jnut7u9ozq8wtut.svg';
export const orders_icon =
  'https://images.meesho.com/superstore/svg_images/orders_icon-tv5ouskbhrl424b5.svg';
export const no_order_found =
  'https://images.meesho.com/superstore/svg_images/no_order_found-5zldk2i6orzlv9u9.svg';
export const offer_coupon =
  'https://images.meesho.com/superstore/svg_images/offer_coupon-998e8bthn5rp6lrs.svg';
export const offer_coupon_background_1 =
  'https://images.meesho.com/superstore/svg_images/offer_coupon_background_1-9wnizw5c5ycp22o3.svg';
export const star_green =
  'https://images.meesho.com/superstore/svg_images/star_green-lm0g1845ejqyio3u.svg';
export const delivery_truck =
  'https://images.meesho.com/superstore/svg_images/delivery_truck-281k8vuspyy2gv7x.svg';
export const fo_info_outline =
  'https://images.meesho.com/superstore/svg_images/fo_info_outline-rzdbd0m0mswtyz8a.svg';
export const savings_icon =
  'https://images.meesho.com/superstore/svg_images/savings_icon-b82nxx0dogcv3sa6.svg';
export const wallet =
  'https://images.meesho.com/superstore/svg_images/wallet-0p5ypollyqtl1a2i.svg';
export const referral_add_amount =
  'https://images.meesho.com/superstore/svg_images/referral_add_amount-ynh4qo5nr5qw17zv.svg';
export const cart_blue =
  'https://images.meesho.com/superstore/svg_images/cart_blue-b7kbxhvskuvv0dwt.svg';
export const cash_withdraw =
  'https://images.meesho.com/superstore/svg_images/cash_withdraw-c5vpfne06xmnh8jr.svg';
export const clock_expiry =
  'https://images.meesho.com/superstore/svg_images/clock_expiry-8icg6ky7inf1l5ho.svg';
export const referral_mobile_hand =
  'https://images.meesho.com/superstore/svg_images/referral_mobile_hand-y7px1uad6nkqj9mk.svg';
export const referral_mobile_lady =
  'https://images.meesho.com/superstore/svg_images/referral_mobile_lady-8xiyk1dk36t3k9d8.svg';
export const referral_money =
  'https://images.meesho.com/superstore/svg_images/referral_money-s1vegpup8wu7svud.svg';
export const line_down_arrow =
  'https://images.meesho.com/superstore/svg_images/line_down_arrow-w0wymfs3tfvyok39.svg';
export const whatsapp_icon_white_outline =
  'https://images.meesho.com/superstore/svg_images/whatsapp-phgnkdpytc5e68gg.svg';
export const search_icon_green =
  'https://images.meesho.com/superstore/svg_images/search-icon-upj19abwnnnf4ejz.svg';
export const mic_icon =
  'https://images.meesho.com/superstore/svg_images/mic_icon-37isyg03j6cblgqo.svg';
export const search_icon_grey =
  'https://images.meesho.com/superstore/svg_images/search-ahycypoz3445nz71.svg';
export const cross_icon =
  'https://images.meesho.com/superstore/svg_images/cross-61jluf60bharsc0z.svg';
export const error_info_icon =
  'https://images.meesho.com/superstore/svg_images/error_info_icon-9dlsp00vqc9wfcvg.svg';
export const whatsapp_icon_white_fill =
  'https://images.meesho.com/superstore/svg_images/whatsapp_icon_white_fill-hd9csluip8nsh206.svg';
export const whatsapp_join_group_icon =
  'https://images.meesho.com/superstore/svg_images/whatsapp-join-group-yeoe4e2jbbjxwsjo.svg';
export const fo_error_circle_filled =
  'https://images.meesho.com/superstore/svg_images/fo_error_circle_filled-ir32y7hulup7mcb7.svg';
export const delivery_truck_big_white =
  'https://images.meesho.com/superstore/svg_images/delivery_truck_big_white-fu01mz1rt2w4xkxh.svg';
export const star_white =
  'https://images.meesho.com/superstore/svg_images/star_white-39otzlj4gofyag20.svg';
export const processing_icon =
  'https://images.meesho.com/superstore/svg_images/processing_icon-1a684j0kt5lkhknw.svg';
export const delivered_pink =
  'https://images.meesho.com/superstore/svg_images/delivered_pink-o10134of6ugjc2fk.svg';
export const out_for_delivery =
  'https://images.meesho.com/superstore/svg_images/out_for_delivery-chdfj2wpxp8u5sb2.svg';
export const order_status_delivered =
  'https://images.meesho.com/superstore/svg_images/order_status_delivered-er4j488jq7mpdxyc.svg';
export const refund_processing =
  'https://images.meesho.com/superstore/svg_images/refund_processing-bfr0qtqonn5yspv4.svg';
export const cross_circle =
  'https://images.meesho.com/superstore/svg_images/cross_circle-pojh5kcs8by6btng.svg';
export const offer_percentage_white =
  'https://images.meesho.com/superstore/svg_images/offer_percentage_white-ohkorkumaurozd8s.svg';
export const right_tick_filled =
  'https://images.meesho.com/superstore/svg_images/right_tick_filled-3v5ilhgcis7l1ov5.svg';
export const cross_black =
  'https://images.meesho.com/superstore/svg_images/cross_black-f8b0pd75m412tnd9.svg';
export const superstore_graphic =
  'https://images.meesho.com/superstore/svg_images/superstore-i2ctf3zjkjtpe1az.svg';
export const fo_radio_unchecked =
  'https://images.meesho.com/superstore/svg_images/fo_radio_unchecked-jx4853tf1kc4a45z.svg';
export const fo_radio_checked =
  'https://images.meesho.com/superstore/svg_images/fo_radio_checked-sez2fd1tvhzhc2jd.svg';
export const fo_error_circle_outline =
  'https://images.meesho.com/superstore/svg_images/fo_error_circle_outline-jarv0gt3q6jrgalh.svg';
export const exclamation_orange =
  'https://images.meesho.com/superstore/svg_images/exclamation_orange-2e7tpqoqac00wxxn.svg';
export const fo_store_filled =
  'https://images.meesho.com/superstore/svg_images/fo_store_filled-gag0w6ni0sd6rtn8.svg';
export const fo_help_circle_filled =
  'https://images.meesho.com/superstore/svg_images/fo_help_circle_filled-2h5lfu0gw7zmeqm6.svg';
export const fo_refund =
  'https://images.meesho.com/superstore/svg_images/fo_refund-84xpkckur8h04e33.svg';
export const fo_close_circle_outline =
  'https://images.meesho.com/superstore/svg_images/fo_close_circle_outline-e827szkj0so92v53.svg';
export const chevron_up_green =
  'https://images.meesho.com/superstore/svg_images/chevron_up_green-e1psejp6430qkmup.svg';
export const chevron_down_green =
  'https://images.meesho.com/superstore/svg_images/chevron_down_green-rgz5qianc9sjutqs.svg';
export const fo_cash =
  'https://images.meesho.com/superstore/svg_images/fo_cash-ir5lp5ct83kkv02s.svg';
export const upload_icon =
  'https://images.meesho.com/superstore/svg_images/upload_icon-uqab6aeel3yaou1u.svg';
export const cross_dark =
  'https://images.meesho.com/superstore/svg_images/cross_dark-roh8tg9phyxorscw.svg';
export const ss_error_triangle =
  'https://images.meesho.com/superstore/svg_images/ss_error_triangle-wcxhurevr2lt10t8.svg';
export const fo_plus_outline =
  'https://images.meesho.com/superstore/svg_images/fo_plus_outline-3aeaqo2iaeog6h7n.svg';
export const fo_minus_outline =
  'https://images.meesho.com/superstore/svg_images/fo_minus_outline-7o67mm5b3900x9mn.svg';
export const fo_warning_filled =
  'https://images.meesho.com/superstore/svg_images/fo_warning_filled-nbhjczjq5boj6z8m.svg';
export const next_deal_time_icon =
  'https://images.meesho.com/superstore/svg_images/next_deal_time_icon-ufkf55j7mjzjhgni.svg';
export const bomb_icon =
  'https://images.meesho.com/superstore/svg_images/bomb_icon-tz48ol3lkl1rdsu3.svg';
export const bomb_icon_grey =
  'https://images.meesho.com/superstore/svg_images/bomb_icon_grey-9emcpnbmmi2onp0v.svg';
export const concentric_circles_lightgrey =
  'https://images.meesho.com/superstore/svg_images/concentric_circles_lightgrey-5599vxgcnomlh47j.svg';
export const customerCare =
  'https://images.meesho.com/superstore/svg_images/customerCare-7wwwfqsbfrzueg36.svg';
export const superstore_with_background =
  'https://images.meesho.com/superstore/svg_images/superstore_with_background-u17af4u9ojg6u77t.svg';
export const search_dark =
  'https://images.meesho.com/superstore/svg_images/search_dark-y3kv03e4wdop2uc3.svg';
export const fo_ready_pickup =
  'https://images.meesho.com/superstore/svg_images/fo_ready_pickup-j7a2rs13ju1xkdpt.svg';
export const bell_ring =
  'https://images.meesho.com/superstore/svg_images/bell_ring-l8tnl18ckeemwe08.svg';
export const check_icon =
  'https://images.meesho.com/superstore/svg_images/check_icon-bmhmo6180mket2tt.svg';
export const lock_icon =
  'https://images.meesho.com/superstore/svg_images/lock_icon-x90emq59ock976gu.svg';
export const chevron_down =
  'https://images.meesho.com/superstore/svg_images/chevron_down-1nrk1av298smzq4o.svg';
export const map_icon =
  'https://images.meesho.com/superstore/svg_images/map_icon-77e6yry0v5hv8lut.svg';
export const call_icon_pink =
  'https://images.meesho.com/superstore/svg_images/call_icon_pink-ns6xjlaorqz5nize.svg';
export const transparent_eclipse =
  'https://images.meesho.com/superstore/svg_images/transparent_eclipse-ho1rz28c90hxbp58.svg';
export const quality_check_logo =
  'https://images.meesho.com/superstore/svg_images/quality_check_logo-h9bjpmwagc7m0csu.svg';
export const shipping_icon =
  'https://images.meesho.com/superstore/svg_images/shipping-innlp6su9tqjvqrq.svg';
export const disallowed_icon =
  'https://images.meesho.com/superstore/svg_images/disallowed_icon-vkiq5aimt0gqsm7u.svg';
export const chevron_right_light_green_circle =
  'https://images.meesho.com/superstore/svg_images/chevron_right_light_green_circle-bqbj8po3izyrmqi6.svg';
export const approved =
  'https://images.meesho.com/superstore/svg_images/approved-9c7yc78qzrqnuu48.svg';
export const rejected_status =
  'https://images.meesho.com/superstore/svg_images/rejected_status-3v0kpkhshudmd0nj.svg';
export const processing_status =
  'https://images.meesho.com/superstore/svg_images/processing_status-7d3kyibwy8r264bf.svg';
export const fo_food_tag_filled_nonveg =
  'https://images.meesho.com/superstore/svg_images/fo_food_tag_filled_nonveg-pmutgmd36ftwzqu1.svg';
export const fo_food_tag_filled_veg =
  'https://images.meesho.com/superstore/svg_images/fo_food_tag_filled_veg-wovv99x0ei3p116c.svg';
export const superstore_text_icon =
  'https://images.meesho.com/superstore/svg_images/superstore_text_icon-fkupgozyigi1n30f.svg';
export const referral_dialog =
  'https://images.meesho.com/superstore/svg_images/referral_dialog-qdhdx1ouj8yffb6b.svg';
export const referral_bottom_tooltip =
  'https://images.meesho.com/superstore/svg_images/referral_bottom_tooltip-vc5q5d2hgv0ft2o7.svg';
export const my_orders =
  'https://images.meesho.com/superstore/svg_images/my_orders-fl0sn6ukw4pgl14l.svg';
export const ss_store_green_color =
  'https://images.meesho.com/superstore/svg_images/ss_store_green_color-tentw4fjxnaerf5s.svg';
export const thunder_bolt_pink =
  'https://images.meesho.com/superstore/svg_images/thunder_bolt_pink-qy6v71ip182q5bfs.svg';
export const checkmark_square =
  'https://images.meesho.com/superstore/svg_images/checkmark-square-c48t832xmczcl6og.svg';
export const selectDownArrow =
  'https://images.meesho.com/superstore/svg_images/selectDownArrow-hqthlr1um8xexgxa.svg';
export const offer_percent_orange =
  'https://images.meesho.com/superstore/svg_images/offer_percent_orange-kmk9bazhvvetmjk9.svg';
export const cart_green_leaf =
  'https://images.meesho.com/superstore/svg_images/cart_green_leaf-ydhhu8iwgjsulubc.svg';
export const chevron_right_grey =
  'https://images.meesho.com/superstore/svg_images/chevron_right_grey-poidaawfvaxbo9cy.svg';
export const location_point_pink =
  'https://images.meesho.com/superstore/svg_images/location_point_pink-m57coz0cqok8ivfi.svg';
export const fo_plus_outline_grey =
  'https://images.meesho.com/superstore/svg_images/fo_plus_outline_grey-z6k9jtc4qs9wka71.svg';
export const warning_white_background =
  'https://images.meesho.com/superstore/svg_images/warning_white_background-k8g0ju26p9c668n0.svg';
export const warning_red_background =
  'https://images.meesho.com/superstore/svg_images/warning_red_background-xkra4xu650x6el8q.svg';
export const celebration_emoji =
  'https://images.meesho.com/superstore/svg_images/celebrations-ucuig7lrydif38yi.svg';
export const ss_logo =
  'https://images.meesho.com/superstore/svg_images/ss_logo-l1m68idc7497q7ho.svg';
export const ss_logo_grocery =
  'https://images.meesho.com/superstore/svg_images/ss_logo_grocery-hb564fcx76zg2cm7.svg';
export const ss_logo_meesho_grocery =
  'https://images.meesho.com/superstore/svg_images/ss_logo_meesho_grocery-wkq5rxl6dm5z9l2l.svg';
export const ss_logo_meesho_grocery_purple_bg =
  'https://images.meesho.com/superstore/svg_images/ss_logo_meesho_grocery_purple_bg-xx5ry6be17mdcpmr.svg';
export const upi_icon =
  'https://images.meesho.com/superstore/svg_images/upi_icon-mn648rul1e7ojc98.svg';
export const shield_icon_blue =
  'https://images.meesho.com/superstore/svg_images/shield_icon_blue-g6kfwzm01iqvhzdc.svg';
export const cod_icon =
  'https://images.meesho.com/superstore/svg_images/cod_icon-7huuqfo1z7zm0vk8.svg';
export const debit_credit_card_icon =
  'https://images.meesho.com/superstore/svg_images/debit_credit_card_icon-396zv6odi49ymo7a.svg';
export const net_banking_icon =
  'https://images.meesho.com/superstore/svg_images/net_banking_icon-6e35jqsl1syl1y4o.svg';
export const chevron_down_dark_grey =
  'https://images.meesho.com/superstore/svg_images/chevron_down_dark_grey-byfy6soxyr761358.svg';
export const chevron_right_dark_grey =
  'https://images.meesho.com/superstore/svg_images/chevron_right_dark_grey-k9j0uvsl9x4kjjba.svg';
export const chevron_right_green =
  'https://images.meesho.com/superstore/svg_images/chevron_right_green-6ghy58dlxo1u0d6z.svg';
export const upi_add_icon =
  'https://images.meesho.com/superstore/svg_images/upi_add_icon-s37myb57nkh5gibb.svg';
export const add_new_card =
  'https://images.meesho.com/superstore/svg_images/add_new_card-jvj0o8gucn0xqzhi.svg';
export const search_icon_blue =
  'https://images.meesho.com/superstore/svg_images/search_icon_blue-0ekakqftuafois1m.svg';
export const heavy_plus =
  'https://images.meesho.com/superstore/svg_images/heavy_plus-5q97ggpf78mydw6e.svg';
export const green_tick =
  'https://images.meesho.com/superstore/svg_images/green-tick-sg72mogau8ighv1h.svg';
export const error_grey_filled =
  'https://images.meesho.com/superstore/svg_images/error_grey_filled-fry13kctd9i9czol.svg';
export const cash_green_icon =
  'https://images.meesho.com/superstore/svg_images/cash_green_icon-w7cuy3va1bieqs11.svg';
export const dark_green_filled_tick =
  'https://images.meesho.com/superstore/svg_images/dark_green_filled_tick-vcpcbkru5qwsmbvs.svg';
export const checkmark_green_circle =
  'https://images.meesho.com/superstore/svg_images/checkmark_green_circle-32fauv1y8pvpg4ru.svg';
export const online_payment_method =
  'https://images.meesho.com/superstore/svg_images/online_payment_method-wzwk293hc1hrm9pr.svg';
export const blue_house_cart_address =
  'https://images.meesho.com/superstore/svg_images/blue_house_cart_address-izak4krzi42ru7bh.svg';
export const retry_logo =
  'https://images.meesho.com/superstore/svg_images/Frame-4zza0u0io95cxdk2.svg';
export const truckPath =
  'https://images.meesho.com/superstore/svg_images/truck-path-b8qiftehhxmys2n7.svg';
export const semiRectangularIcon =
  'https://images.meesho.com/superstore/svg_images/semi-rectangular-icon-qsr0837wwe8ejz3n.svg';
export const rectangularPriceIcon =
  'https://images.meesho.com/superstore/svg_images/rectangular-price-icon-u0077u79ed9kg8dm.svg';
export const orangeArrowPriceTag =
  'https://images.meesho.com/superstore/svg_images/orange-arrow-price-tag-5gefgnc0ewok8r3m.svg';
export const myOrdersLogo =
  'https://images.meesho.com/superstore/svg_images/my-orders-c61kc03v7zvmaaac.svg';
export const grocery_header_logo =
  'https://images.meesho.com/superstore/svg_images/grocery-header-ro467n72j5jrnwwo.svg';
export const discountIconLogo =
  'https://images.meesho.com/superstore/svg_images/discount-icon-wate2xkok6ut5gxa.svg';
export const deliveryTruckYellowBlue =
  'https://images.meesho.com/superstore/svg_images/delivery-truck-yellow-blue-c4x9nl5hihdw08nt.svg';
export const blueBackgroundImageStarFrame =
  'https://images.meesho.com/superstore/svg_images/blue-backgroung-image-star-frame-ven2gdpbbjujjulj.svg';
export const deliveryAttemptUnsuccessfulWarningIcon =
  'https://images.meesho.com/superstore/svg_images/delivery_attempt_unsuccessful_warning-cn9wdvqb31m3ye6w.svg';
export const camera_icon =
  'https://images.meesho.com/superstore/svg_images/camera_icon-qxe52qxs848xffr4.svg';
export const plus_icon =
  'https://images.meesho.com/superstore/svg_images/plus_icon-gzlrqz77x2zd2yb8.svg';
export const feedback_submitted =
  'https://images.meesho.com/superstore/svg_images/banner-feedback-completed-fe613rcuzkp919io.svg';
export const unratedStar =
  'https://images.meesho.com/superstore/svg_images/unrated-star-0m8qyf9jt5himvuo.svg';
export const whiteCrossIcon =
  'https://images.meesho.com/superstore/svg_images/Cross-6f5gk6a12g18os7x.svg';
export const chevron_left_dark_grey =
  'https://images.meesho.com/superstore/svg_images/left_dark_grey_chevron-ufi7fxbxs87c0l02.svg';
export const right_chevron_dark_grey =
  'https://images.meesho.com/superstore/svg_images/right_dark_grey_chevron-lpxvvw4swl665jsk.svg';
export const green_like =
  'https://images.meesho.com/superstore/svg_images/like_green-lve57s58pvzy2hmb.svg';
export const white_like =
  'https://images.meesho.com/superstore/svg_images/like_white-dsswznpa9iwb5vsr.svg';
export const grey_like =
  'https://images.meesho.com/superstore/svg_images/grey_like-ze3yhk7y1mbdgbvk.svg';
export const green_star =
  'https://images.meesho.com/superstore/svg_images/green_star-s42h36eejwn3vnqx.svg';
