/* eslint-disable import/prefer-default-export */
export function getUTMParams() {
  const urlParams = new URLSearchParams(window.location.search);
  const utmParamStr = urlParams.get('utm') ?? '';

  const utmParams = utmParamStr.split(' ').reduce((_utmParams, param) => {
    const [key, value] = param.split('=');
    if (key) _utmParams[key] = value;
    return _utmParams;
  }, {});
  return utmParams;
}

export function getPDPQueryParams(entity_data, setDestination = true) {
  const urlParams = new URLSearchParams(window.location.search);
  // in case type is PDM, PDM remains the old one
  if (setDestination) {
    urlParams.set('destination_entity_type', entity_data.group_type);
    if (entity_data.group_id)
      urlParams.set(
        'destination_entity_id',
        encodeURIComponent(entity_data.group_id)
      );
    if (entity_data.name)
      urlParams.set('destination_entity_name', entity_data.name);
  }
  urlParams.set('origin_entity_type', entity_data.group_type);
  if (entity_data.group_id)
    urlParams.set('origin_entity_id', encodeURIComponent(entity_data.group_id));
  urlParams.set('origin_entity_index', entity_data.index);
  return urlParams.toString();
}

export function decodeQueryParam(p) {
  try {
    return decodeURIComponent(p.replace(/\+/g, ' '));
  } catch (e) {
    return null;
  }
}

const APP_INSTALL_CAMPAIGN_UTM_MEDIUMS = ['google', 'fb', 'googleapp'];

export function hasAppInstallCampaignUtmMedium(medium) {
  return APP_INSTALL_CAMPAIGN_UTM_MEDIUMS.includes(medium);
}
