export const PAYMENT = {
  SET_AVAILABLE_UPI_APPS_FROM_ANDROID: 'SET_AVAILABLE_UPI_APPS_FROM_ANDROID',
  SET_AVAILABLE_PAYMENT_OPTIONS: 'SET_AVAILABLE_PAYMENT_OPTIONS',
  SET_AVAILABLE_PAYMENT_OPTIONS_LOADING:
    'SET_AVAILABLE_PAYMENT_OPTIONS_LOADING',
  SET_AVAILABLE_PAYMENT_OPTIONS_ERROR: 'SET_AVAILABLE_PAYMENT_OPTIONS_ERROR',
  SET_UPI_INTENT_FLOW_RESPONSE: 'SET_UPI_INTENT_FLOW_RESPONSE',
};

export const REVIEWS_REDUCER = {
  SET_REVIEWS: 'SET_REVIEWS',
  SET_LOADING: 'SET_LOADING',
};
