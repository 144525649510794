export const ADD_PRODUCT_CLICKED = {
  TYPE: {
    INCREASE: 'Increase',
    DECREASE: 'Decrease',
  },
};

export const PAYMENT_MIX_PANEL_EVENTS = {
  PAYMENT_PAGE_DROPDOWN_CLICKED: 'Payment page dropdown clicked',
  PAYMENT_METHOD_SELECTED_FOR_JUSPAY: 'Payment method selected for juspay',
  NEW_PAYMENT_METHOD_ADDITION_CLICKED: 'New payment method addition clicked',
  PAYMENT_ADDITION_ERROR_THROWN: 'Payment addition error thrown',
  NEW_CARD_SAVE_PERMISSION: 'New card save permission',
  PAY_NOW_CLICKED: 'Pay now clicked',
  VIEW_BREAKUP_CLICKED: 'View price break up clicked',
  TRANSACTION_FAILED_ACTIVITY: 'Transaction failed activity',
};

export const GROCERY_INITIAL_INTERACTION = 'Grocery Initial Interaction';

export const USER_COHORT_TYPE = {
  ZERO_ORDER: 'ZERO_ORDER',
  ONE_ORDER_PLUS: 'ONE_ORDER_PLUS',
};

export const MEESHO_GROCERY_WEB_VITALS = 'Meesho Grocery Web Vitals';

export const EVENTS_WITH_LOADING_TIME_DATA = [
  'Webview Launch',
  'Website Launch',
  'Web Vitals',
];

export const LOADING_TIME_EVENT_CONSTANTS = {
  index_html_file_load_time: 'index_html_file_load_time',
  index_tsx_file_load_time: 'index_tsx_file_load_time',
  launch_app_time: 'launch_app_time',
  index_html_file_load_start_time: 'index_html_file_load_start_time',
  index_html_file_load_end_time: 'index_html_file_load_end_time',
  main_js_file_load_start_time: 'main_js_file_load_start_time',
  main_js_file_load_end_time: 'main_js_file_load_end_time',
  mainComp_js_file_load_start_time: 'mainComp_js_file_load_start_time',
  mainComp_js_file_load_end_time: 'mainComp_js_file_load_end_time',
  config_api_call_start: 'config_api_call_start',
  config_api_call_end: 'config_api_call_end',
  custom_ttfb_time: 'custom_ttfb_time',
  custom_fcp_time: 'custom_fcp_time',
  custom_lcp_time: 'custom_lcp_time',
};

export const GROCERY_WEBVIEW_LAUNCH = 'Grocery Webview Launch';

export const RATING_REVIEW_EVENTS = {
  HOME_PAGE_RATINGS_INTERACTION: 'Home Page Ratings Interaction',
  RATING_SUBMITTED: 'Rating Submitted',
  FEEDBACK_SUBMITTED_SCREEN_VIEWED: 'Feedback Submitted Screen Viewed',
  RATE_PRODUCTS_CTA_CLICKED: 'Rate Products CTA Clicked',
  ADD_PHOTOS_CTA_CLICKED: 'Add Photos CTA Clicked',
  ADD_OR_EDIT_REVIEW_CTA_CLICKED: 'Add or Edit Review CTA Clicked',
  PDP_RATING_REVIEW_VIEWED: 'PDP Rating Review Viewed',
  PDP_RATING_REVIEW_INTERACTION: 'PDP Rating Review Interaction',
};

export const BACK_BUTTON_CLICKED_EVENT = 'Back Button Clicked';
