import { REVIEWS_REDUCER } from 'constants/actions';
import { createNextState } from '@reduxjs/toolkit';

const intialState = {
  loading: false,
  reviews: [],
};

const reviewsReducer = (state = intialState, action) => {
  const { type, payload } = action;

  return createNextState(state, (draft) => {
    switch (type) {
      case REVIEWS_REDUCER.SET_REVIEWS:
        draft.reviews = payload;
        return draft;
      case REVIEWS_REDUCER.SET_LOADING:
        draft.loading = payload;
        return draft;
      default:
        return draft;
    }
  });
};

export default reviewsReducer;
