const routeConfig = {
  home: {
    path: '/',
    page: 'Home',
    exact: true,
    pathUrlRegexPattern: /^\/$/,
  },
  homeDeliveryAddressList: {
    path: '/home-delivery-address-list',
    page: 'Home Delivery Address List',
    exact: true,
    pathUrlRegexPattern: /^\/home-delivery-address-list$/,
  },
  homeDeliveryAddressNew: {
    path: '/home-delivery-new-address',
    page: 'Home Delivery New Address',
    exact: true,
    pathUrlRegexPattern: /^\/home-delivery-new-address$/,
  },
  search: {
    path: '/search',
    page: 'Search PLP',
    exact: true,
    pathUrlRegexPattern: /^\/search$/,
  },
  cart: {
    path: '/cart',
    page: 'Cart',
    exact: true,
    pathUrlRegexPattern: /^\/cart$/,
  },
  orderDetails: {
    path: '/order-details/:order_number',
    page: 'Order Details',
    exact: true,
    pathUrlRegexPattern: /^\/order-details\/([a-zA-Z0-9]+)$/,
  },
  widget: {
    path: '/widget/:widget_id',
    page: 'Widget PLP',
    exact: true,
    pathUrlRegexPattern: /^\/widget\/([a-zA-Z0-9]+)$/,
  },
  orderSuccess: {
    path: '/order/success/:order_number',
    page: 'Order Success',
    exact: true,
    pathUrlRegexPattern: /^\/order\/success\/([a-zA-Z0-9]+)$/,
  },
  orderRefund: {
    path: '/order/:order_number/refund',
    page: 'Order Refund',
    exact: true,
    pathUrlRegexPattern: /^\/order\/([a-zA-Z0-9]+)\/refund$/,
  },
  categoryPage: {
    path: '/category/:id',
    page: 'Category PLP',
    exact: true,
    pathUrlRegexPattern: /^\/category\/([a-zA-Z0-9]+)$/,
  },
  bannerPage: {
    path: '/banner/:banner_id',
    page: 'Banner PLP',
    exact: true,
    pathUrlRegexPattern: /^\/banner\/([a-zA-Z0-9]+)$/,
  },
  offerProductsPage: {
    path: '/offer/:group_id',
    page: 'Offer Products Page',
    exact: true,
    pathUrlRegexPattern: /^\/offer\/([a-zA-Z0-9]+)$/,
  },
  orderHistory: {
    path: '/orders',
    page: 'Order History',
    exact: true,
    pathUrlRegexPattern: /^\/orders$/,
  },
  pdp: {
    path: '/product/:id',
    page: 'PDP',
    exact: true,
    pathUrlRegexPattern: /^\/product\/([a-zA-Z0-9]+)$/,
  },
  referralTc: {
    path: '/referral/terms-and-conditions',
    page: 'Referral T&C',
    exact: true,
    pathUrlRegexPattern: /^\/referral\/terms-and-conditions$/,
  },
  referralReferEarn: {
    path: '/referral/refer-earn',
    page: 'Referral Refer and Earn',
    exact: true,
    pathUrlRegexPattern: /^\/referral\/refer-earn$/,
  },
  payment: {
    path: '/payment',
    page: 'Payment',
    exact: true,
    pathUrlRegexPattern: /^\/payment$/,
  },
  paymentTransactionManager: {
    path: '/payment-transaction-manager',
    page: 'Payment Transaction Manager',
    exact: true,
    pathUrlRegexPattern: /^\/payment-transaction-manager$/,
  },
  unserviceable: {
    path: '/unserviceable',
    page: 'Unserviceable',
    exact: true,
    pathUrlRegexPattern: /^\/unserviceable$/,
  },
  rateOrder: {
    path: '/rate-order/:order_number',
    page: 'Rate Order',
    exact: true,
    pathUrlRegexPattern: /^\/rate-order\/([a-zA-Z0-9]+)$/,
  },
  reviewProduct: {
    path: '/review-product/order/:order_number/sub-order/:sub_order_number',
    page: 'Review Product',
    exact: true,
    pathUrlRegexPattern: /^\/review-product\/([a-zA-Z0-9]+)$/,
  },
  feedbackSubmitted: {
    path: '/feedback-submitted/order/:order_number/sub-order/:sub_order_number',
    page: 'Feedback Submitted',
    exact: true,
    pathUrlRegexPattern: /^\/feedback-submitted\/([a-zA-Z0-9]+)$/,
  },
};

export function getRouteInfo(path) {
  return (
    Object.keys(routeConfig)
      .map((routeKey) => routeConfig[routeKey])

      // eslint-disable-next-line arrow-body-style
      .find((routeObj) => {
        // make this rule warning
        return routeObj.path === path;
      })
  );
}
export default routeConfig;
