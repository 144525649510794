interface IGetCurrentPageName {
  currentPathName: string;
  routeConfig: {
    [key: string]: any;
  };
}

export function getCurrentPageName({
  currentPathName,
  routeConfig,
}: IGetCurrentPageName): string | null {
  let currentPageName = '404 Page Not Found';
  Object.keys(routeConfig).some((routeConfigKey) => {
    const result = currentPathName.match(
      routeConfig[routeConfigKey].pathUrlRegexPattern
    );
    if (result !== null) {
      currentPageName = routeConfig[routeConfigKey].page;
      return true;
    }
    return false;
  });
  return currentPageName;
}
