import { createNextState } from '@reduxjs/toolkit';
import { PAYMENT } from 'constants/actions';

const intialState = {
  availableUpiAppsFromAndroid: null,
  availablePaymentOptions: {
    isLoading: false,
    hasError: false,
    data: {
      payment_modes: null,
      prepaid_payment_options: null,
    },
  },
  upiIntentFlowResponse: null,
};

const payment = (state = intialState, action) => {
  const { type, payload } = action;

  return createNextState(state, (draft) => {
    switch (type) {
      case PAYMENT.SET_AVAILABLE_UPI_APPS_FROM_ANDROID:
        draft.availableUpiAppsFromAndroid = payload;
        return draft;
      case PAYMENT.SET_AVAILABLE_PAYMENT_OPTIONS:
        draft.availablePaymentOptions.data.payment_modes =
          payload?.payment_modes || null;
        draft.availablePaymentOptions.data.prepaid_payment_options =
          payload?.prepaid_payment_options || null;
        return draft;
      case PAYMENT.SET_AVAILABLE_PAYMENT_OPTIONS_LOADING:
        draft.availablePaymentOptions.isLoading = payload;
        return draft;
      case PAYMENT.SET_AVAILABLE_PAYMENT_OPTIONS_ERROR:
        draft.availablePaymentOptions.hasError = payload;
        return draft;
      case PAYMENT.SET_UPI_INTENT_FLOW_RESPONSE:
        draft.upiIntentFlowResponse = payload;
        return draft;
      default:
        return draft;
    }
  });
};

export default payment;
