import {
  MIXPANEL_TOKEN,
  REACT_APP_ANALYTICS_API_HOST,
  REACT_APP_ENVIRONMENT,
} from 'config';
import { BATCH_VIEW_PROPERTIES, XO_TOKEN } from 'constants/index';
import { delay } from 'utils/asyncUtils';
import { getSearchQueryParams } from 'utils/queryStringUtils';
import { getUTMParams } from 'helpers/urlParams';
import { getLocalStorage } from '../utils';
import { plotlineEventsTrack } from './plotline';
import { getItemFromLS } from 'utils/memoryUtils';
import { isAndroidUserAgent } from 'mobileInterface/helpers/androidHelpers';
import { isIphoneUserAgent } from 'mobileInterface/helpers/iPhoneHelpers';
import { getPlatformType } from 'mobileInterface/helpers/commonDeviceHelpers';
import { errorObserverData } from 'components/ErrorObserver/helper';
import { RELEASE_VERSION } from 'constants/releaseConstant';
import { getLoadTimestampPropertiesMixpanel } from 'utils/loadTimeDataUtils';
import { EVENTS_WITH_LOADING_TIME_DATA } from 'constants/eventsConstant';
import { ENVIRONMENTS } from 'constants/environment.constants';

const productViews = {};
const categoryViews = {};
const bannerViews = {};
const widgetViews = {};
const nddBannerV1Views = {};
const nddBannerV2Views = {};
const dsrBannerViews = {};
const pricePerceptionBannerViews = {};

let mixpanel = null;

const eventQueue = new Set();
const peoplePropertyQueue = new Set();
const superPropertyQueue = new Set();

const utmParams = getUTMParams();
const urlQueryParams = getSearchQueryParams();

const flushBulkEvents = (eventDataObj, eventName) => {
  const data = {
    event_name: eventName,
    event_data: {
      view_data: Object.keys(eventDataObj)
        .filter((key) => !eventDataObj[key].isSent)
        .map((key) => {
          const { isSent, ...eventData } = eventDataObj[key];
          return eventData;
        }),
    },
  };
  // Reference: https://stackoverflow.com/a/68492307
  // eslint-disable-next-line no-use-before-define
  mixPanelEventData(data, { transport: 'sendBeacon' });
  Object.keys(eventDataObj).forEach((key) => {
    eventDataObj[key].isSent = true;
  });
};

const shouldFlushEvents = (eventDataObj, batchSize = 1) =>
  Object.keys(eventDataObj).filter((key) => !eventDataObj[key].isSent).length >=
  batchSize;

export const collectProductViewEvent = (params) => {
  const key = `${params['Product ID']}-${params['Group Entity Type']}-${params['Group Entity ID']}`;

  if (!productViews[key]) {
    productViews[key] = { ...params, timestamp: Date.now() };
  }
  if (
    shouldFlushEvents(productViews, BATCH_VIEW_PROPERTIES.PRODUCT.BATCH_SIZE)
  ) {
    flushBulkEvents(productViews, BATCH_VIEW_PROPERTIES.PRODUCT.EVENT_NAME);
  }
};

export const collectCategoryViewEvent = (params) => {
  const key = params['Category ID'];
  if (!categoryViews[key]) {
    categoryViews[key] = { ...params, timestamp: Date.now() };
  }
  if (
    shouldFlushEvents(categoryViews, BATCH_VIEW_PROPERTIES.CATEGORY.BATCH_SIZE)
  ) {
    flushBulkEvents(categoryViews, BATCH_VIEW_PROPERTIES.CATEGORY.EVENT_NAME);
  }
};

export const collectBannerViewEvent = (params) => {
  const key = params['Banner ID'];
  if (!bannerViews[key]) {
    bannerViews[key] = { ...params, timestamp: Date.now() };
  }

  if (shouldFlushEvents(bannerViews, BATCH_VIEW_PROPERTIES.BANNER.BATCH_SIZE)) {
    flushBulkEvents(bannerViews, BATCH_VIEW_PROPERTIES.BANNER.EVENT_NAME);
  }
};

export const collectDSRBannerViewEvent = (params) => {
  const key = params['Banner ID'];
  if (!dsrBannerViews[key]) {
    dsrBannerViews[key] = { ...params, timestamp: Date.now() };
  }

  if (
    shouldFlushEvents(
      dsrBannerViews,
      BATCH_VIEW_PROPERTIES.DSR_BANNER.BATCH_SIZE
    )
  ) {
    flushBulkEvents(
      dsrBannerViews,
      BATCH_VIEW_PROPERTIES.DSR_BANNER.EVENT_NAME
    );
  }
};

export const collectNddBannerV1ViewEvent = (params) => {
  const key = `${params['Source']}-${params['Source Id']}`;
  if (!nddBannerV1Views[key]) {
    nddBannerV1Views[key] = { ...params, timestamp: Date.now() };
  }
  if (
    shouldFlushEvents(
      nddBannerV1Views,
      BATCH_VIEW_PROPERTIES.NDD_BANNER_V1.BATCH_SIZE
    )
  ) {
    flushBulkEvents(
      nddBannerV1Views,
      BATCH_VIEW_PROPERTIES.NDD_BANNER_V1.EVENT_NAME
    );
  }
};

export const collectNddBannerV2ViewEvent = (params) => {
  const key = `${params['Source']}-${params['Source Id']}`;
  if (!nddBannerV2Views[key]) {
    nddBannerV2Views[key] = { ...params, timestamp: Date.now() };
  }
  if (
    shouldFlushEvents(
      nddBannerV2Views,
      BATCH_VIEW_PROPERTIES.NDD_BANNER_V2.BATCH_SIZE
    )
  ) {
    flushBulkEvents(
      nddBannerV2Views,
      BATCH_VIEW_PROPERTIES.NDD_BANNER_V2.EVENT_NAME
    );
  }
};

export const collectWidgetViewEvent = (params) => {
  const key = `${params['Widget ID']}-${params['Widget Group ID']}}`;
  if (!widgetViews[key]) {
    widgetViews[key] = { ...params, timestamp: Date.now() };
  }
  if (shouldFlushEvents(widgetViews, BATCH_VIEW_PROPERTIES.WIDGET.BATCH_SIZE)) {
    flushBulkEvents(widgetViews, BATCH_VIEW_PROPERTIES.WIDGET.EVENT_NAME);
  }
};

export const collectPricePerceptionBannerViewEvent = (params) => {
  const { id, ...rest } = params;
  const key = `${params['Screen Name']}-${params['Group Entity ID']}}`;

  if (!pricePerceptionBannerViews[key]) {
    pricePerceptionBannerViews[key] = { ...rest, timestamp: Date.now() };
  }

  if (
    shouldFlushEvents(
      pricePerceptionBannerViews,
      BATCH_VIEW_PROPERTIES.PRICE_PERCEPTION_BANNER.BATCH_SIZE
    )
  ) {
    flushBulkEvents(
      pricePerceptionBannerViews,
      BATCH_VIEW_PROPERTIES.PRICE_PERCEPTION_BANNER.EVENT_NAME
    );
  }
};

export const flushAllBulkEvents = () => {
  if (shouldFlushEvents(productViews, 1))
    flushBulkEvents(productViews, BATCH_VIEW_PROPERTIES.PRODUCT.EVENT_NAME);

  if (shouldFlushEvents(categoryViews, 1))
    flushBulkEvents(categoryViews, BATCH_VIEW_PROPERTIES.CATEGORY.EVENT_NAME);

  if (shouldFlushEvents(bannerViews, 1))
    flushBulkEvents(bannerViews, BATCH_VIEW_PROPERTIES.BANNER.EVENT_NAME);

  if (shouldFlushEvents(widgetViews, 1))
    flushBulkEvents(widgetViews, BATCH_VIEW_PROPERTIES.WIDGET.EVENT_NAME);

  if (shouldFlushEvents(nddBannerV1Views, 1))
    flushBulkEvents(
      nddBannerV1Views,
      BATCH_VIEW_PROPERTIES.NDD_BANNER_V1.EVENT_NAME
    );
  if (shouldFlushEvents(nddBannerV2Views, 1))
    flushBulkEvents(
      nddBannerV2Views,
      BATCH_VIEW_PROPERTIES.NDD_BANNER_V2.EVENT_NAME
    );

  if (shouldFlushEvents(pricePerceptionBannerViews, 1)) {
    flushBulkEvents(
      pricePerceptionBannerViews,
      BATCH_VIEW_PROPERTIES.PRICE_PERCEPTION_BANNER.EVENT_NAME
    );
  }
};

export async function collectmixpanelSuperProperty(data) {
  if (!mixpanel) {
    return superPropertyQueue.add(data);
  }
  mixpanel.register(data);
}

// todo check for empty data object
export async function mixPanelEventData(data) {
  if (!mixpanel) {
    return eventQueue.add(data);
  }

  if (!data.event_data) {
    data.event_data = {};
  }
  const eventDealerToken = data.event_data['Dealer Token'];
  const localStorageDealerToken = getLocalStorage('dealer_token');
  const user_info = getLocalStorage('user') ?? '{}';

  data.event_data['User Id'] = JSON.parse(user_info)?.customer_id;
  data.event_data['User Platform'] = 'Meesho-App';
  data.event_data['Platform'] = getPlatformType();
  data.event_data['Is Meesho User'] = true;
  data.event_data['UTM Source'] =
    utmParams.source ?? urlQueryParams.entered_from ?? 'Direct';
  data.event_data['UTM Campaign'] = utmParams.campaign ?? 'Direct';
  data.event_data['UTM Content'] = utmParams.content;
  data.event_data['UTM Medium'] = utmParams.medium ?? 'Direct';

  let xoox = {};
  let xooxObj = {};
  if (isAndroidUserAgent()) {
    xooxObj = JSON.parse(window?.xoox?.getParams?.() ?? '{}');
  } else if (isIphoneUserAgent()) {
    xooxObj = JSON.parse(window?.localStorage?.getItem(XO_TOKEN) ?? '{}');
  }
  delete xooxObj['Authorization'];
  xoox = xooxObj;

  let isLoggedIn = false;

  if (Object.keys(xoox).length > 0) {
    isLoggedIn = Boolean(xoox['APP-USER-ID']);
    data.event_data['Meesho User Id'] = xoox['APP-USER-ID'];
    data.event_data.meesho_user_id = xoox['APP-USER-ID'];
    data.event_data.app_version_code = xoox['App-Version-Code'];
    data.event_data['Session Id'] = xoox['App-Session-Id'];
    data.event_data['Instance Id'] = xoox['Instance-Id'];
  }

  if (!eventDealerToken)
    data.event_data['Dealer Token'] = localStorageDealerToken;

  if (EVENTS_WITH_LOADING_TIME_DATA.includes(data.event_name)) {
    data.event_data = {
      ...data.event_data,
      ...getLoadTimestampPropertiesMixpanel(),
    };
  }

  data.event_name = `${!isLoggedIn ? 'Anonymous ' : ''}${data?.event_name}`;

  if (!isLoggedIn && Boolean(getItemFromLS('anonymousUserId'))) {
    data.event_data['meesho_anonymous_id'] = getItemFromLS('anonymousUserId');
  }
  const catalogFeedViewVariantType =
    JSON.parse(user_info)?.ux_ab_experiments?.feed_view_type;
  if (catalogFeedViewVariantType) {
    data.event_data['Catalog Feed View Variant Type'] =
      catalogFeedViewVariantType;
  }

  const realEstateAudienceType =
    JSON.parse(user_info)?.ux_ab_experiments?.real_estate_audience_type;
  if (realEstateAudienceType) {
    data.event_data['Homepage Real Estate Audience Type'] =
      realEstateAudienceType;
  }

  data.event_data['Connection Type'] =
    navigator?.connection?.effectiveType ?? 'Unknown';
  data.event_data['RAM in GB'] = navigator?.deviceMemory;
  if (navigator?.connection?.downlink) {
    data.event_data['Internet Speed (KB/s)'] =
      navigator.connection.downlink * 125;
  }

  data.event_data['Release Version'] = RELEASE_VERSION;
  data.event_data['Service Worker Active Status'] = Boolean(
    navigator?.serviceWorker?.controller
  );
  plotlineEventsTrack(data.event_name);
  if (
    [ENVIRONMENTS.DEVELOPMENT, ENVIRONMENTS.PRE_PROD].includes(
      REACT_APP_ENVIRONMENT
    )
  ) {
    console.log('MIXPANEL = ', data);
  }
  mixpanel.track(data.event_name, data.event_data);
  return null;
}

export async function collectMixpanelPeopleProperty(user_id) {
  peoplePropertyQueue.add({ $name: user_id });
}

export const initializeMixpanel = () => {
  import(
    /* webpackChunkName: "Meshlytics" */
    '@meesho/web-meshlytics'
  )
    .then((meshlytics) => {
      mixpanel = meshlytics.default;

      meshlytics.default.init(MIXPANEL_TOKEN, {
        api_host: REACT_APP_ANALYTICS_API_HOST,
        loaded(_mixpanel) {
          const distinct_id = _mixpanel.get_distinct_id();
          _mixpanel.people.set({ $unique_id: distinct_id });
          _mixpanel.identify();
          errorObserverData.setMixpanelDistinctId(distinct_id);
          const data = {
            Source: utmParams.source ?? 'Direct',
            Medium: utmParams.medium ?? 'Direct',
            Campaign: utmParams.campaign ?? 'Direct',
          };
          _mixpanel.people.set_once(data);
          const super_data = {
            'User Type': 'Customer',
          };
          _mixpanel.register(super_data);
          setTimeout(() => {
            if (peoplePropertyQueue.size > 0) {
              const peoplePropertyArray = Array.from(peoplePropertyQueue);
              peoplePropertyQueue.clear();
              peoplePropertyArray.forEach((peopleProperty) => {
                _mixpanel.people.set(peopleProperty);
              });
            }
            if (superPropertyQueue.size > 0) {
              const superPropertyArray = Array.from(superPropertyQueue);
              superPropertyQueue.clear();
              superPropertyArray.forEach((superProperty) => {
                _mixpanel.register(superProperty);
              });
            }
            if (eventQueue.size > 0) {
              const eventArray = Array.from(eventQueue);
              eventQueue.clear();
              eventArray.forEach((eventObj) => {
                mixPanelEventData(eventObj);
              });
            }
          }, 0);
        },
      });
    })
    .catch((err) => {
      if (
        [ENVIRONMENTS.DEVELOPMENT, ENVIRONMENTS.PRE_PROD].includes(
          REACT_APP_ENVIRONMENT
        )
      ) {
        console.error('error loading meshlytics library', err);
      }
    });
};

export function logEventInMeeshoMixpanel(eventName, data) {
  data.ss_source = utmParams.source ?? urlQueryParams.entered_from ?? 'Direct';
  const { campaign, medium, content } = getUTMParams();
  data.utm_campaign = campaign || 'Direct';
  data.utm_medium = medium || 'Direct';
  data.utm_content = content || 'Direct';
  data.source = getPlatformType().toLowerCase();
  data['Release Version'] = RELEASE_VERSION;

  try {
    if (isAndroidUserAgent()) {
      if (
        typeof window.mixpanel !== 'undefined' &&
        typeof window.mixpanel?.pushEvent === 'function'
      ) {
        window.mixpanel?.pushEvent(eventName, JSON.stringify(data));
      }
    } else if (isIphoneUserAgent()) {
      window?.webkit?.messageHandlers?.trackEvent?.postMessage?.({
        eventName,
        jsonProps: JSON.stringify(data),
      });
    }
  } catch (e) {}
}
