export function setItemInLS(
  obj: {
    [key: string]: any;
  } = {}
): void {
  try {
    if (Boolean(window.localStorage)) {
      const keyName = Object.keys(obj)[0];
      if (keyName) {
        window.localStorage.setItem(keyName, obj[keyName]);
      }
    }
  } catch (e) {}
}

export function getItemFromLS(keyName: string): string | null | undefined {
  try {
    if (Boolean(window.localStorage)) {
      return window.localStorage.getItem(keyName);
    }
  } catch (e) {}
}

export function removeItemFromLS(keyName: string): void {
  try {
    if (Boolean(window.localStorage)) {
      window.localStorage.removeItem(keyName);
    }
  } catch (e) {}
}

export function setItemInSessionStorage(
  obj: {
    [key: string]: string;
  } = {}
): void {
  try {
    if (Boolean(window.sessionStorage)) {
      const keyName = Object.keys(obj)[0];
      if (keyName) {
        window.sessionStorage.setItem(keyName, obj[keyName]);
      }
    }
  } catch (e) {}
}

export function getItemFromSessionStorage(
  keyName: string
): string | null | undefined {
  try {
    if (Boolean(window.sessionStorage)) {
      return window.sessionStorage.getItem(keyName);
    }
  } catch (e) {}
}
