import ReactDOM from 'react-dom';
import { Suspense, lazy, useEffect } from 'react';

import { collectWebVitalMetrics } from 'javascript/webVitalsCollector';
import { REACT_APP_ENVIRONMENT } from 'config';
import { isWebviewEnvironmentMode } from 'mobileInterface/helpers/commonDeviceHelpers';
import {
  initializeMixpanel,
  logEventInMeeshoMixpanel,
  mixPanelEventData,
} from 'javascript/mixpanel';
import { getCurrentPageName } from 'utils/pageUtils';
import routeConfig from 'routeConfig';
import ErrorObserver from 'components/ErrorObserver';
import LoaderOverlay from 'components/loader/LoaderOverlay';
import { xooxInjectorFromURL } from 'mobileInterface/helpers/commonDeviceHelpers';
import { collectLoadTimeEventProperties } from 'utils/loadTimeDataUtils';
import { collectWebMetricsData } from 'mobileInterface/helpers/androidHelpers';
import usePerformanceTracker from 'hooks/usePerformanceTracker';
import {
  GROCERY_WEBVIEW_LAUNCH,
  LOADING_TIME_EVENT_CONSTANTS,
} from 'constants/eventsConstant';
import { logEventInFarmisoMixpanel } from 'javascript/bridgeMixpanelUtils';
import observability from 'utils/observability';
import { ENVIRONMENTS } from 'constants/environment.constants';

observability?.init?.();
collectWebMetricsData('js_file_loaded', Date.now());
collectWebMetricsData('index_html_file_loaded', window.pageLoadedTimeStamp);
collectLoadTimeEventProperties(
  LOADING_TIME_EVENT_CONSTANTS.index_html_file_load_time,
  window.pageLoadedTimeStamp
);
collectLoadTimeEventProperties(
  LOADING_TIME_EVENT_CONSTANTS.index_tsx_file_load_time,
  Date.now()
);

usePerformanceTracker();
setTimeout(() => {
  initializeMixpanel();
}, 100);

const Main = lazy(() => import(/* webpackChunkName: "MainComp" */ './Main'));
const PublicLandingPage = lazy(
  () =>
    import(
      /* webpackChunkName: "PublicLandingPage" */ 'pages/PublicLandingPage/index'
    )
);

function LaunchApp() {
  collectLoadTimeEventProperties(
    LOADING_TIME_EVENT_CONSTANTS.launch_app_time,
    Date.now()
  );

  const isWebviewEnvironment = isWebviewEnvironmentMode();
  useEffect(() => {
    const currentPageName = getCurrentPageName({
      currentPathName: window.location?.pathname ?? '',
      routeConfig,
    });

    const eventProperties = {
      index_timestamp: window.pageLoadedTimeStamp,
      'Target Screen Name': currentPageName,
    };

    logEventInMeeshoMixpanel('Superstore Webview Loaded', eventProperties);

    const timeStamp = Date.now();
    const mixpanelData = {
      'User Type': 'Customer',
      'User Platform': 'Meesho App',
      timestamp: timeStamp,
      webViewLoadTime: timeStamp - window.pageLoadedTimeStamp,
      'Target Screen Name': currentPageName,
    };
    logEventInFarmisoMixpanel(GROCERY_WEBVIEW_LAUNCH, {
      ...eventProperties,
      ...mixpanelData,
    });
    mixPanelEventData({
      event_name: isWebviewEnvironment
        ? 'Webview Launch'
        : 'POW Website Launch',
      event_data: mixpanelData,
    });
  }, []);

  return isWebviewEnvironment ? <Main /> : <PublicLandingPage />;
}

const mountReactApp = () => {
  ReactDOM.render(
    <ErrorObserver>
      <Suspense
        fallback={
          <LoaderOverlay overlay={false} source={'index.tsx Suspense'} />
        }
      >
        <LaunchApp />
      </Suspense>
    </ErrorObserver>,
    document.getElementById('root')
  );
};

async function doMountApp() {
  if ([ENVIRONMENTS.DEVELOPMENT].includes(REACT_APP_ENVIRONMENT)) {
    try {
      // @ts-ignore
      const { setupMockData } = await import('./mock-data.js');
      setupMockData();
    } catch (err) {
      console.error(
        'please add mock-data.js file in src, copy object shape from mock-data.sample.js'
      );
    }
  }
  if (
    [ENVIRONMENTS.DEVELOPMENT, ENVIRONMENTS.PRE_PROD].includes(
      REACT_APP_ENVIRONMENT
    )
  ) {
    await xooxInjectorFromURL();
  }
  mountReactApp();
}

doMountApp();

collectWebVitalMetrics();
