import axios from 'axios';
import { REACT_APP_CLIENT_BASE_URL } from 'config';

const headers = {
  'Content-Type': 'application/json',
};

const JUSPAY_API = axios.create({
  headers,
  baseURL: REACT_APP_CLIENT_BASE_URL + '/api/juspay',
});

export default JUSPAY_API;
