import { XO_TOKEN } from 'constants/index';
import { mixPanelEventData } from 'javascript/mixpanel';
import { useBankDetailsStore } from 'reducers/useBankDetailsStore';
import { setItemInLS } from 'utils/memoryUtils';

const IOS_BRIDGE_TIMEOUT = 6 * 1000;

function getExecutionTime(start_time) {
  const execTime = window.performance.now() - start_time;
  return parseFloat(execTime.toFixed(0));
}
export function getIphoneAppVersionCode() {
  try {
    const xooxObj = JSON.parse(window?.localStorage?.getItem(XO_TOKEN) ?? '{}');
    return parseInt(xooxObj?.['App-Version-Code']);
  } catch (e) {
    return null;
  }
}

export const isIphoneUserAgent = () => {
  return Boolean(window?.webkit?.messageHandlers?.generateXoox);
};

export const getIPhoneAuthInfo = () =>
  new Promise((resolve, reject) => {
    try {
      window.appInitiate = (tokenResponse) => {
        setItemInLS({ [XO_TOKEN]: JSON.stringify(tokenResponse) });
        resolve({ ...tokenResponse });
      };
      if (window?.webkit?.messageHandlers?.generateXoox) {
        window?.webkit?.messageHandlers?.generateXoox?.postMessage?.({
          message: 'generateXoox',
        });
      } else {
        reject(null);
      }
    } catch (e) {
      reject(null);
    }
  });

export const getIPhoneRefreshedAuthInfo = () =>
  new Promise((resolve, reject) => {
    try {
      window.appRefreshInitiate = (tokenResponse) => {
        setItemInLS({ [XO_TOKEN]: JSON.stringify(tokenResponse) });
        resolve(tokenResponse);
      };
      if (window?.webkit?.messageHandlers?.refreshXoox) {
        window?.webkit?.messageHandlers?.refreshXoox?.postMessage?.({
          message: 'refresh xoox',
        });
      } else {
        reject(null);
      }
    } catch (e) {
      reject(null);
    }
  });

export const handleIOSLogin = () => {
  window.webkit?.messageHandlers?.onLoginLaunch?.postMessage?.({});
};

export const getIphoneGaidAPI = () => {
  let timeoutPromiseTimeout;
  const timeoutPromise = new Promise((resolve) => {
    timeoutPromiseTimeout = setTimeout(() => {
      resolve(null);
    }, IOS_BRIDGE_TIMEOUT);
  });
  const mainPromise = new Promise((resolve) => {
    try {
      window.setGaid = (gaid) => {
        clearTimeout(timeoutPromiseTimeout);
        resolve(gaid);
      };
      if (window?.webkit?.messageHandlers?.getGaid) {
        window?.webkit?.messageHandlers?.getGaid?.postMessage?.({});
      } else {
        resolve(null);
      }
    } catch (e) {
      resolve(null);
    }
  });
  return Promise.race([mainPromise, timeoutPromise]);
};

export const getCheckLocationPermissionAPI = () => {
  let timeoutPromiseTimeout;
  const timeoutPromise = new Promise((resolve) => {
    timeoutPromiseTimeout = setTimeout(() => {
      resolve(false);
    }, IOS_BRIDGE_TIMEOUT);
  });
  const mainPromise = new Promise((resolve) => {
    try {
      window.setCheckLocationPermission = (payload) => {
        clearTimeout(timeoutPromiseTimeout);
        resolve(payload);
      };
      if (window?.webkit?.messageHandlers?.checkLocationPermission) {
        window?.webkit?.messageHandlers?.checkLocationPermission?.postMessage?.(
          {}
        );
      } else {
        resolve(false);
      }
    } catch (e) {
      resolve(false);
    }
  });
  return Promise.race([mainPromise, timeoutPromise]);
};

export const getRequestLocationAPI = () => {
  const start_time = window.performance.now();
  mixPanelEventData({
    event_name: 'Location Permission - Watch Location Start',
  });
  let timeoutPromiseTimeout;
  const timeoutPromise = new Promise((resolve, reject) => {
    timeoutPromiseTimeout = setTimeout(() => {
      reject({
        message: 'Timed out manually',
        code: 4,
      });
    }, IOS_BRIDGE_TIMEOUT);
  });
  const mainPromise = new Promise((resolve, reject) => {
    try {
      window.setLocationResponseIOS = (latitude, longitude, accuracy) => {
        const positionData = {
          latLngData: { latitude, longitude, accuracy },
          pollingTime: `${getExecutionTime(start_time)} ms`,
          numberOfAttempts: 1,
        };
        mixPanelEventData({
          event_name: 'Location Permission - Watch Location Success',
          event_data: {
            locationPositions: [{ accuracy, latitude, longitude }],
            Count: positionData.numberOfAttempts,
            'Time Taken': positionData.pollingTime,
          },
        });
        clearTimeout(timeoutPromiseTimeout);
        resolve(positionData);
      };
      if (window?.webkit?.messageHandlers?.requestLocation) {
        window?.webkit?.messageHandlers?.requestLocation?.postMessage?.({});
      } else {
        throw new Error();
      }
    } catch (error) {
      const errorData = {
        code: 401,
        message: 'Not Found',
      };
      mixPanelEventData({
        event_name: 'Location Permission - Watch Location Error',
        event_data: {
          'Error code': errorData.code,
          'Error Message': errorData.message,
        },
      });
      reject(errorData);
    }
  });
  return Promise.race([mainPromise, timeoutPromise]);
};

export const getBankDetailsResponseAPI = () => {
  let timeoutPromiseTimeout;
  const timeoutPromise = new Promise((resolve) => {
    timeoutPromiseTimeout = setTimeout(() => {
      resolve(false);
    }, IOS_BRIDGE_TIMEOUT);
  });
  const mainPromise = new Promise((resolve) => {
    try {
      window.setBankDetailsResponse = (payload) => {
        clearTimeout(timeoutPromiseTimeout);
        useBankDetailsStore.getState().setHasBankDetailsUpdated(payload);
        resolve(payload);
      };
      if (window?.webkit?.messageHandlers?.getBankDetailsResponse) {
        window?.webkit?.messageHandlers?.getBankDetailsResponse?.postMessage?.(
          {}
        );
      } else {
        resolve(false);
      }
    } catch (e) {
      resolve(false);
    }
  });
  return Promise.race([mainPromise, timeoutPromise]);
};
