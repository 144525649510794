import { create } from 'zustand';

interface IBankDetailsStore {
  hasBankDetailsUpdated: boolean | null;
  setHasBankDetailsUpdated: (payload: boolean | null) => void;
}

export const useBankDetailsStore = create<IBankDetailsStore>((set) => ({
  hasBankDetailsUpdated: null,
  setHasBankDetailsUpdated: (payload) =>
    set(() => ({ hasBankDetailsUpdated: payload })),
}));
