import { isAndroidUserAgent } from 'mobileInterface/helpers/androidHelpers';
import { isIphoneUserAgent } from 'mobileInterface/helpers/iPhoneHelpers';

class ErrorObserverInternalData {
  private messhoUserId: string | null;
  private mixpanelDistinctId: string | null;

  constructor() {
    this.messhoUserId = null;
    this.mixpanelDistinctId = null;
  }

  setMesshoUserId(messhoUserId: string): void {
    this.messhoUserId = messhoUserId;
  }

  setMixpanelDistinctId(mixpanelDistinctId: string): void {
    this.mixpanelDistinctId = mixpanelDistinctId;
  }

  getMesshoUserId(): string | null {
    return this.messhoUserId;
  }

  getMixpanelDistinctId(): string | null {
    return this.mixpanelDistinctId;
  }
}

export const errorObserverData = new ErrorObserverInternalData();

const ignorableErrors = [
  /Loading chunk [\d]+ failed/,
  /Loading CSS chunk [\d]+ failed/,
  /Failed to execute 'observe' on 'PerformanceObserver/,
  /globalThis is not defined/,
];

function checkIgnorableErrorMessage(errorMessage) {
  return ignorableErrors.some((ignorableErrorMessage) =>
    new RegExp(ignorableErrorMessage).test(errorMessage)
  );
}

export function extractErrorDetails(error) {
  if (!(error instanceof Error)) {
    return null;
  }

  try {
    const errorMessage = error.message;
    const hasIgnorableErrorMessaage = checkIgnorableErrorMessage(errorMessage);
    if (hasIgnorableErrorMessaage) {
      return null;
    }
    const errorType = error.constructor.name;
    const stackLines = error.stack.split('\n');
    let regex;
    if (isAndroidUserAgent()) {
      regex = /\((.*):(\d+):(\d+)\)/;
    } else if (isIphoneUserAgent()) {
      regex = /\@(.*):(\d+):(\d+)/;
    }
    for (const line of stackLines) {
      const match = line.match(regex);
      if (match) {
        const [, fileName, lineNumber, columnNumber] = match;
        if (fileName && lineNumber && columnNumber) {
          return {
            errorMessage: `${errorType}: ${errorMessage}`,
            sourceFileUrl: fileName,
            lineNumber: Number(lineNumber),
            columnNumber: Number(columnNumber),
            browserUserAgent: window.navigator?.userAgent,
            meeshoUserId: errorObserverData.getMesshoUserId(),
            mixpanelDistinctId: errorObserverData.getMixpanelDistinctId(),
            currentUrl: window.location?.href,
            timeStamp: new Date().toLocaleString('en-US', {
              timeZone: 'Asia/Kolkata',
            }),
          };
        }
      }
    }
    throw new Error('No match found in error stack');
  } catch (err) {
    return null;
  }
}
