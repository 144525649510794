import {
  collectmixpanelSuperProperty,
  logEventInMeeshoMixpanel,
  mixPanelEventData,
} from 'javascript/mixpanel';
import store from 'state/reduxStore';
import {
  getAndroidAuthInfo,
  getAndroidRefreshedAuthInfo,
  handleAndroidLogin,
  isAndroidUserAgent,
} from 'mobileInterface/helpers/androidHelpers';
import {
  getIPhoneAuthInfo,
  getIPhoneRefreshedAuthInfo,
  getIphoneGaidAPI,
  handleIOSLogin,
  isIphoneUserAgent,
} from 'mobileInterface/helpers/iPhoneHelpers';
import { getPlatformType } from 'mobileInterface/helpers/commonDeviceHelpers';
import {
  setAvailableUpiAppsFromAndroid,
  setUpiIntentFlowResponseInRedux,
} from 'actions/payment';
import { errorObserverData } from 'components/ErrorObserver/helper';
import { useBankDetailsStore } from 'reducers/useBankDetailsStore';
import { useAuthStore } from 'reducers/useAuthStore';
import { useCommonStore } from 'reducers/useCommonStore';

class MobileInterface {
  constructor() {
    if (window) {
      window.getXo = this.getXo;
      window.refreshXo = this.getXo;
      // android callback functions
      window.reactInterface = {
        setLocationPermissionResponse: this.setLocationPermissionResponse,
        setShareClickData: this.setShareClickData,
        getGpsResponse: this.getGpsResponse,
        getBankDetailsResponse: this.getBankDetailsResponse,
        setMeeshoLoginSuccess: this.setMeeshoLoginSuccess.bind(this),
        onBackPressed: this.onBackPressed,
        onReferralCodeShared: this.onReferralCodeShared,
        handleAndroidBackButtonClick: this.handleAndroidBackButtonClick,
        getSpeechRecognitionResponse: this.getSpeechRecognitionResponse,
        setUpiIntentFlowResponse: this.setUpiIntentFlowResponse,
      };
      // ios callback functions
      window.loginSuccessful = this.setMeeshoLoginSuccess.bind(this);
      window.setLocationPermissionResponseIOS =
        this.setLocationPermissionResponse;
    }
  }

  async getXo() {
    try {
      let xoox = null;
      let gaid = null;
      if (isAndroidUserAgent()) {
        xoox = await getAndroidAuthInfo();
        if (
          window.farmiso?.getGaid &&
          typeof window.farmiso.getGaid === 'function'
        ) {
          gaid = await window.farmiso.getGaid();
        }
      } else if (isIphoneUserAgent()) {
        xoox = await getIPhoneAuthInfo();
        gaid = await getIphoneGaidAPI();
      }
      if (Boolean(gaid)) {
        xoox.gaid = gaid;
        collectmixpanelSuperProperty({ gaid });
      }
      if (xoox && xoox['APP-USER-ID']) {
        errorObserverData.setMesshoUserId(xoox['APP-USER-ID']);
      }
      delete xoox['Authorization'];
      useAuthStore.getState().setXooX(xoox);
      return xoox;
    } catch (e) {
      mixPanelEventData({
        event_name: 'Mobile Interface Error',
        event_data: {
          origin: 'getXo',
        },
      });
    }
    return null;
  }

  async refreshXo() {
    try {
      let xoox = null;
      let gaid = null;
      if (isAndroidUserAgent()) {
        xoox = await getAndroidRefreshedAuthInfo();
        if (
          window.farmiso?.getGaid &&
          typeof window.farmiso.getGaid === 'function'
        ) {
          gaid = await window.farmiso.getGaid();
        }
      } else if (isIphoneUserAgent()) {
        xoox = await getIPhoneRefreshedAuthInfo();
        gaid = await getIphoneGaidAPI();
      }
      if (Boolean(gaid)) {
        xoox.gaid = gaid;
        collectmixpanelSuperProperty({ gaid });
      }
      delete xoox['Authorization'];
      useAuthStore.getState().setXooX(xoox);
    } catch (e) {
      mixPanelEventData({
        event_name: 'Mobile Interface Error',
        event_data: {
          origin: 'refreshXO',
        },
      });
    }
  }

  setLocationPermissionResponse() {
    useCommonStore.getState().setTriggerRequestUserLocationPermission(true);
  }

  setMeeshoLoginSuccess() {
    this.getXo();
  }

  shareDataOnWhatsApp(data) {
    try {
      if (isAndroidUserAgent() && window.farmiso?.shareOnWhatsApp) {
        window.farmiso.shareOnWhatsApp(JSON.stringify(data));
      } else if (isIphoneUserAgent()) {
        const navigatorObject = window?.navigator;
        navigatorObject?.share?.(data);
      }
    } catch (e) {
      mixPanelEventData({
        event_name: 'Mobile Interface Error',
        event_data: {
          origin: 'shareDataOnWhatsApp',
        },
      });
    }
  }

  setShareClickData(data) {
    try {
      const mix_panel_data = JSON.parse(data);
      mixPanelEventData(mix_panel_data);
    } catch (e) {
      mixPanelEventData({
        event_name: 'Mobile Interface Error',
        event_data: {
          origin: 'setShareClickData',
        },
      });
    }
  }

  getGpsResponse() {
    useCommonStore.getState().setTriggerRequestUserLocationPermission(true);
  }

  getBankDetailsResponse(isSuccess) {
    const status = isSuccess === 'true';
    useBankDetailsStore.getState().setHasBankDetailsUpdated(status);
  }

  onBackPressed() {
    mixPanelEventData({
      event_name: 'Superstore Back Button Clicked',
      event_data: {
        source: getPlatformType().toLowerCase(),
      },
    });

    logEventInMeeshoMixpanel('Superstore Back Button Click', {});
  }

  shouldHideBottomNavBar(payload) {
    try {
      if (isAndroidUserAgent() && window?.farmiso?.shouldHideBottomNavBar) {
        window.farmiso?.shouldHideBottomNavBar(payload);
      } else if (isIphoneUserAgent()) {
        if (payload === true) {
          window?.webkit?.messageHandlers?.hideBottomBar?.postMessage?.({});
        } else {
          window?.webkit?.messageHandlers?.showBottomBar?.postMessage?.({});
        }
      }
      mixPanelEventData({
        event_name:
          payload === true
            ? 'Meesho Bottom Nav Hidden'
            : 'Meesho Bottom Nav Visible',
      });
    } catch (e) {
      mixPanelEventData({
        event_name: 'Mobile Interface Error',
        event_data: {
          origin: 'shouldHideBottomNavBar',
        },
      });
    }
  }

  isMeeshoLoginProvided() {
    try {
      if (isAndroidUserAgent()) {
        return Boolean(window?.farmiso?.onLoginLaunch);
      } else if (isIphoneUserAgent()) {
        return Boolean(window?.webkit?.messageHandlers?.onLoginLaunch);
      }
      return false;
    } catch (e) {
      mixPanelEventData({
        event_name: 'Mobile Interface Error',
        event_data: {
          origin: 'isMeeshoLoginProvided',
        },
      });
      return false;
    }
  }

  triggerMeeshoLogin() {
    try {
      if (isAndroidUserAgent()) {
        handleAndroidLogin();
      } else if (isIphoneUserAgent()) {
        handleIOSLogin();
      }
    } catch (err) {
      mixPanelEventData({
        event_name: 'Mobile Interface Error',
        event_data: {
          origin: 'triggerMeeshoLogin',
        },
      });
    }
  }

  shareReferralCodeInterface(data) {
    try {
      if (isAndroidUserAgent() && window.farmiso?.shareReferralCode) {
        window.farmiso.shareReferralCode(JSON.stringify(data));
      } else if (isIphoneUserAgent()) {
        // no logic required here
      }
    } catch (e) {
      mixPanelEventData({
        event_name: 'Mobile Interface Error',
        event_data: {
          origin: 'shareReferralCodeInterface',
        },
      });
    }
  }

  onReferralCodeShared(details) {
    mixPanelEventData({
      event_name: 'Share Widget App Selected',
      event_data: {
        App_Selected: details,
      },
    });
  }

  shouldDisableAndriodBackButton(isDisabled, callbackFn) {
    try {
      if (window && window.farmiso?.disableBack) {
        window.farmiso.disableBack(isDisabled);
        window.reactInterface.handleAndroidBackButtonClick = callbackFn;
        return true;
      }
      return false;
    } catch (e) {
      mixPanelEventData({
        event_name: 'Mobile Interface Error',
        event_data: {
          origin: 'shouldDisableAndriodBackButton',
        },
      });
    }
  }

  handleAndroidBackButtonClick() {
    mixPanelEventData({
      event_name: `${getPlatformType()} Back Button Clicked`,
    });
  }

  startVoiceSearch() {
    try {
      if (window && window.farmiso?.startVoiceSearch) {
        window.farmiso.startVoiceSearch();
      }
    } catch (e) {
      mixPanelEventData({
        event_name: 'Mobile Interface Error',
        event_data: {
          origin: 'startVoiceSearch',
        },
      });
    }
  }

  getSpeechRecognitionResponse(speechResponseText) {
    if (typeof speechResponseText === 'string') {
      useCommonStore.getState().setVoiceSearchResponseText(speechResponseText);
    }
  }

  getAvailableUpiApps() {
    let availableUpiAppsFromAndroid = [];
    try {
      if (window?.juspay?.getAvailableUpiApps) {
        const response = window.juspay.getAvailableUpiApps();
        const parsedResponse = JSON.parse(response);
        if (parsedResponse && parsedResponse.length > 0) {
          availableUpiAppsFromAndroid = parsedResponse;
        }
      }
    } catch (e) {
      mixPanelEventData({
        event_name: 'Mobile Interface Error',
        event_data: {
          origin: 'getAvailableUpiApps',
        },
      });
    } finally {
      store.dispatch(
        setAvailableUpiAppsFromAndroid(availableUpiAppsFromAndroid)
      );
    }
  }

  invokeUpiIntentFlow(payload) {
    try {
      if (window && window.juspay?.invokeUpiIntentFlow) {
        window.juspay.invokeUpiIntentFlow(JSON.stringify(payload));
      }
    } catch (e) {
      mixPanelEventData({
        event_name: 'Mobile Interface Error',
        event_data: {
          origin: 'invokeUpiIntentFlow',
        },
      });
    }
  }

  setUpiIntentFlowResponse(response) {
    try {
      if (typeof response === 'string') {
        const upiIntentFlowResponse = JSON.parse(response);
        store.dispatch(setUpiIntentFlowResponseInRedux(upiIntentFlowResponse));
      }
    } catch (e) {
      store.dispatch(
        setUpiIntentFlowResponseInRedux({
          hasJuspayJsonParseError: true,
        })
      );
    }
  }
}

const mobileInterface = new MobileInterface();

export default mobileInterface;
