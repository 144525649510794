import { useEffect } from 'react';
import { extractErrorDetails } from './helper';
import { pushClientError } from 'actions/apiCalls';
import { mixPanelEventData } from 'javascript/mixpanel';

async function reportError(error) {
  const errorDetails = extractErrorDetails(error);
  if (errorDetails) {
    try {
      await pushClientError(errorDetails);
      mixPanelEventData({
        event_name: 'App Error Reported',
        event_data: {
          error_class: 'React Error Boundary',
          error_origin: 'Client',
          error_message: errorDetails?.errorMessage,
        },
      });
    } catch (e) {}
  }
}

function ErrorFallback({ error }) {
  useEffect(() => {
    if (error) {
      reportError(error);
    }
  }, [error]);
  return (
    <div className="flex-container-between error-fallback white-background full-page text-center">
      <div style={{ width: '100%' }}>
        <h3>Sorry, something went wrong</h3>
        <button onClick={() => window.location.reload()} type="button">
          Please try again
        </button>
      </div>
    </div>
  );
}

export default ErrorFallback;
