import { useEffect } from 'react';
import MeeshoLoader from './MeeshoLoader';
import { mixPanelEventData } from 'javascript/mixpanel';

function LoaderOverlay({ overlay, source = null }) {
  useEffect(() => {
    document.body.classList.add('stop-scrolling');

    if (source !== null) {
      mixPanelEventData({
        event_name: 'Loader Overlay Shown',
        event_data: {
          source,
        },
      });
    }
    return () => {
      document.body.classList.remove('stop-scrolling');
    };
  }, []);

  return (
    <div
      style={{
        backgroundColor: overlay ? 'rgba(0, 0, 0, 0.55)' : '#f1f1f0',
        height: '100vh',
        width: '100%',
        maxWidth: '600px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <MeeshoLoader />
    </div>
  );
}

export default LoaderOverlay;
