/**
 *
 * @param {*}, String: optional urlParams, default  value is window.location.search String
 * @returns Object of shape  {key: value} where key is a queryParam and it's value
 */
// eslint-disable-next-line import/prefer-default-export
export const getSearchQueryParams = (urlParams = window.location.search) => {
  const searchParams = new URLSearchParams(urlParams);
  const searchParamsObj = {};
  for (const [key, value] of searchParams.entries()) {
    searchParamsObj[key] = value;
  }
  return searchParamsObj;
};
