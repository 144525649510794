import { IConfig, IPickupStore } from 'types/config.types';
import api from 'helpers/axios';
import { getUTMParams } from 'helpers/urlParams';
import { collectmixpanelSuperProperty } from 'javascript/mixpanel';
import { IXoox } from 'types/xoox.types';
import { getSearchQueryParams } from 'utils/queryStringUtils';
import { create } from 'zustand';

interface TAuthStore {
  xoox: IXoox | null;
  config: IConfig;
  loginAttempts: number;
  setXooX: (payload: IXoox | null) => void;
  setConfig: (payload: IConfig) => void;
  setLoginAttempts: (payload: number) => void;
  setIsFirstOrderPlaced: () => void;
  setDealerInfoAction: (payload: IPickupStore) => void;
}

const { entered_from: enteredFrom = 'Direct' } = getSearchQueryParams();

const utmParams: any = getUTMParams();

export const useAuthStore = create<TAuthStore>((set) => ({
  xoox: null,
  config: {},
  loginAttempts: 0,
  setXooX: (payload) => {
    api.defaults.headers.common = {
      ...api.defaults.headers.common,
      ...payload,
    };
    const mixPanelSuperData = {
      'Is Meesho User': true,
      'Meesho User Id:': payload['APP-USER-ID'],
      meesho_user_id: payload['APP-USER-ID'],
      app_version_code: payload['App-Version-Code'],
      'UTM Source': utmParams.source || enteredFrom,
      'UTM Campaign': utmParams.campaign ?? 'Direct',
      'UTM Content': utmParams.content,
      'UTM Medium': utmParams.medium ?? 'Direct',
      'Session Id': payload['App-Session-Id'],
      'Instance Id': payload['Instance-Id'],
    };
    collectmixpanelSuperProperty(mixPanelSuperData);
    set(() => ({ xoox: payload }));
  },
  setConfig: (payload) => set(() => ({ config: payload })),
  setLoginAttempts: (payload) => set(() => ({ loginAttempts: payload })),
  setIsFirstOrderPlaced: () =>
    set((state) => ({
      config: {
        ...state?.config,
        user_info: { ...state?.config?.user_info, is_first_order_placed: true },
      },
    })),
  setDealerInfoAction: (payload) =>
    set((state) => ({
      config: {
        ...state?.config,
        pick_up_store: payload,
      },
    })),
}));
