import axios from 'axios';
import {
  REACT_APP_CLIENT_AXIOS_TIMEOUT,
  REACT_APP_CLIENT_BASE_URL,
  REACT_APP_ENVIRONMENT,
} from 'config';
import mobileInterface from 'mobileInterface/MobileInterface';
import { getLocalStorage } from '../utils/index';
import { UNAUTHORIZED_STATUS } from 'constants/index';
import { ENVIRONMENTS } from 'constants/environment.constants';

const headers = {
  'Content-Type': 'application/json',
};

const api = axios.create({
  headers,
  baseURL: REACT_APP_CLIENT_BASE_URL,
  timeout: REACT_APP_CLIENT_AXIOS_TIMEOUT,
  withCredentials: [ENVIRONMENTS.DEVELOPMENT].includes(REACT_APP_ENVIRONMENT),
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status } = error.response ?? {};
    if (status === UNAUTHORIZED_STATUS) {
      mobileInterface.refreshXo();
    }
    return Promise.reject(error);
  }
);

export default api;
