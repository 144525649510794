import { ss_logo } from 'constants/svgImageCollection';

function MeeshoLoader() {
  return (
    <div className="meesho-loader-container">
      <div className="vertical-line" />
      <img src={ss_logo} alt="meesho-grocery-loader" crossOrigin="anonymous" />
    </div>
  );
}
export default MeeshoLoader;
