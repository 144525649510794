import { RELEASE_VERSION } from 'constants/releaseConstant';
import { getUTMParams } from 'helpers/urlParams';
import { isAndroidUserAgent } from 'mobileInterface/helpers/androidHelpers';
import { getPlatformType } from 'mobileInterface/helpers/commonDeviceHelpers';
import { getSearchQueryParams } from 'utils/queryStringUtils';
const utmParams: any = getUTMParams();
const urlQueryParams = getSearchQueryParams();

export function logEventInFarmisoMixpanel(
  eventName: string,
  data: { [key: string]: any }
) {
  data.ss_source = utmParams.source ?? urlQueryParams.entered_from ?? 'Direct';
  // @ts-ignore
  const { campaign, medium, content } = getUTMParams();
  data.utm_campaign = campaign || 'Direct';
  data.utm_medium = medium || 'Direct';
  data.utm_content = content || 'Direct';
  data.source = getPlatformType().toLowerCase();
  data['Release Version'] = RELEASE_VERSION;

  try {
    if (isAndroidUserAgent()) {
      if (
        typeof window.mixpanel !== 'undefined' &&
        typeof window.mixpanel?.pushEventToFarmisoMixpanel === 'function'
      ) {
        window.mixpanel.pushEventToFarmisoMixpanel(
          eventName,
          JSON.stringify(data)
        );
      }
    }
  } catch (e) {}
}
