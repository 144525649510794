import routeConfig from 'routeConfig';

// REMEMBER : make this change in env
export const REACT_APP_ENVIRONMENT = 'pre-prod'; // process.env.REACT_APP_ENVIRONMENT;

export const REACT_APP_CLIENT_BASE_URL = process.env.REACT_APP_CLIENT_BASE_URL;

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GMAP_API_KEY;

export const MIXPANEL_TOKEN = process.env.REACT_APP_MIX_PANEL_TOKEN;

export const BACK_TO_MEESHO_URL = process.env.REACT_APP_ANDROID_CLOSE_URL;

export const BACK_TO_MEESHO_URL_FROM_FRAGMENT =
  process.env.REACT_APP_ANDROID_BACK_URL;

export const MEESHO_BANK_DETAILS_PAGE =
  process.env.REACT_APP_ANDROID_BANK_DETAILS_PAGE;

export const MEESHO_ORDER_DETAILS_PAGE =
  process.env.REACT_APP_ANDROID_ORDERS_PAGE;

export const MEESHO_ANDROID_IOS_HOME_PAGE_LINK =
  process.env.REACT_APP_IOS_HOME_PAGE;

export const MEESHO_IOS_EDIT_BANK_DETAILS_PAGE =
  process.env.REACT_APP_IOS_EDIT_BANK_DETAILS_PAGE;

export const PLOTLINE_SDK_URL = process.env.REACT_APP_PLOTLINE_SDK_URL;

export const PLOTLINE_API_KEY = process.env.REACT_APP_PLOTLINE_API_KEY;

export const MEESHO_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.meesho.supply';

export const BE_BASE_URL = process.env.REACT_APP_BE_BASE_URL;

export const CSO_PROJECT_TOKEN = process.env.REACT_APP_CSO_PROJECT_TOKEN;

export const CSO_SOURCE_MAPS_LOGS_URL = `${process.env.REACT_APP_CSO_SOURCE_MAPS_LOGS_URL}/api/v1/source-map-logs`;

export const JUSPAY_MERCHANT_ID = process.env.REACT_APP_JUSPAY_MERCHANT_ID;

export const PAY_V3_CDN_URL = process.env.REACT_APP_PAY_V3_CDN_URL;

export const JUSPAY_TRANSACTION_RETURN_URL =
  REACT_APP_CLIENT_BASE_URL + routeConfig.paymentTransactionManager.path;

export const PUBLIC_LANDING_PAGE_IMAGE =
  process.env.REACT_APP_PUBLIC_LANDING_PAGE_IMAGE;

export const REACT_APP_GMAP_API_KEY = process.env.REACT_APP_GMAP_API_KEY;

export const REACT_APP_ANALYTICS_API_HOST =
  process.env.REACT_APP_ANALYTICS_API_HOST;

export const REACT_APP_CLIENT_AXIOS_TIMEOUT =
  process.env.REACT_APP_CLIENT_AXIOS_TIMEOUT ?? 10000;

export const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS;

export const SENTRY_ENABLE_FULL_SAMPLING =
  process.env.REACT_APP_SENTRY_ENABLE_FULL_SAMPLING;
