import { SENTRY_DNS } from 'config';
import { ObservabilityErrorFrom } from 'constants/observability';
import { ErrorInfo } from 'react';
import { init, captureException, setUser } from '@sentry/react';

class SentryObservability {
  private sentryDns: string;

  constructor(sentryDns: string) {
    this.sentryDns = sentryDns;
  }

  public async init() {
    try {
      init({
        dsn: this.sentryDns,
        ignoreErrors: [/Java object is gone/],
      });
    } catch {}
  }

  public async setUser(user: Record<string, string>) {
    try {
      setUser(user);
    } catch {}
  }

  public async captureExeption({
    error,
    error_from,
  }: {
    error_from: ObservabilityErrorFrom;
    error: Error;
  }) {
    try {
      captureException(error, {
        tags: {
          ErrorCategory: error_from || ObservabilityErrorFrom.CAPTURE_EXCEPTION,
        },
      });
    } catch {}
  }

  public async captureErrorBoundary(error: Error, errorInfo: ErrorInfo) {
    try {
      captureException(error, {
        extra: { componentStack: errorInfo.componentStack },
        tags: {
          ErrorCategory: ObservabilityErrorFrom.REACT_ERROR_BOUNDARY,
        },
      });
    } catch {}
  }
}

export default ((): SentryObservability | undefined => {
  if (SENTRY_DNS) {
    return new SentryObservability(SENTRY_DNS);
  }
  return undefined;
})();
