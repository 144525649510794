export const orderRefundStatus = {
  PROCESSING: 'PROCESSING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const orderStatus = {
  DELIVERED: 'DELIVERED',
  REFUND_PROCESSING: 'REFUND_PROCESSING',
  REFUND_PROCESSED: 'REFUND_PROCESSED',
  CANCELLED: 'CANCELLED',
  OUT_FOR_DELIVERY: 'OUT_FOR_DELIVERY',
};

export const refundPayoutStatus = {
  SUCCESS: 'SUCCESS',
  PENDING: 'PENDING',
  FAILED: 'FAILED',
};

export const bannerTypes = {
  PRODUCT: 'PRODUCT',
  STATIC: 'STATIC',
  WEB_URL: 'WEB_URL',
};

export const BATCH_VIEW_PROPERTIES = {
  PRODUCT: {
    EVENT_NAME: 'Product Views',
    BATCH_SIZE: 20,
  },
  CATEGORY: {
    EVENT_NAME: 'Category Views',
    BATCH_SIZE: 2,
  },
  BANNER: {
    EVENT_NAME: 'Banner Views',
    BATCH_SIZE: 2,
  },
  WIDGET: {
    EVENT_NAME: 'Widget Views',
    BATCH_SIZE: 4,
  },
  WEB_VITALS: {
    EVENT_NAME: 'Web Vitals',
  },
  NDD_BANNER_V1: {
    EVENT_NAME: 'Delivery Time Banner V1 Viewed',
    BATCH_SIZE: 2,
  },
  NDD_BANNER_V2: {
    EVENT_NAME: 'Delivery Time Banner V2 Viewed',
    BATCH_SIZE: 2,
  },
  DSR_BANNER: {
    EVENT_NAME: 'DSR banner viewed',
    BATCH_SIZE: 1,
  },
  PRICE_PERCEPTION_BANNER: {
    EVENT_NAME: 'Offer Banner Viewed',
    BATCH_SIZE: 2,
  },
};

export const DELIVERY_OPTION = {
  PICKUP: 'PICKUP',
  PICKUP_AND_HOME_DELIVERY: 'PICKUP_AND_HOME_DELIVERY',
  HOME_DELIVERY: 'HOME_DELIVERY',
};

export const FALLBACK_ERROR_MESSAGE = 'Unexpected error. try again';

export const BANK_DETAIL_STATUS = {
  VALID: 'VALID',
  INVALID: 'INVALID',
  PENDING: 'PENDING',
};

export const DELIVERY_OPTION_MIXPANEL = {
  PICKUP: 'PICKUP',
  PICKUP_AND_HOME_DELIVERY: 'PICKUP AND HOME DELIVERY',
  HOME_DELIVERY: 'HOME DELIVERY',
};

export const INDIAN_STATES = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Dadra and Nagar Haveli',
  'Daman & Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Jammu & Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttar Pradesh',
  'Uttrakhand',
  'West Bengal',
  'Others',
];

export const CITY_MAP = {
  mysore: {
    city_name: 'Mysore',
    city_type: 'MYSORE',
  },
  nagpur: {
    city_name: 'Nagpur',
    city_type: 'NAGPUR',
  },
};

export const DISCOVERY_MODES = {
  PINCODE: 'PINCODE',
  CITY: 'CITY',
  DEFAULT_CITY: 'DEFAULT_CITY',
  UTM_CITY: 'UTM_CITY',
  GEOLOCATION: 'GEOLOCATION',
  GEOLOCATION_ID: 'GEOLOCATION_ID',
};

export const DISCOVERY_MIXPANEL_NAMES = {
  PINCODE: 'Pincode',
  CITY: 'City',
  GEOLOCATION: 'Lat-Long',
  GEOLOCATION_ID: 'Search',
};

export const WIDGET_TYPES = {
  MONTHLY_BASKET_WIDGET: 'MONTHLY_BASKET_WIDGET',
  PERSONALISED_PRODUCT_WIDGET: 'PERSONALISED_PRODUCT_WIDGET',
  PREVIOUSLY_PURCHASED_PRODUCTS: 'PREVIOUSLY_PURCHASED_PRODUCTS',
  DELIVERY_FEE_RELEVANT_PRODUCTS: 'DELIVERY_FEE_RELEVANT_PRODUCTS',
  NEW_CART_UPSELL_PRODUCTS_STATIC_VIEW_ENABLED:
    'NEW_CART_UPSELL_PRODUCTS_STATIC_VIEW_ENABLED',
  NEW_CART_UPSELL_PRODUCTS_STATIC_VIEW_DISABLED:
    'NEW_CART_UPSELL_PRODUCTS_STATIC_VIEW_DISABLED',
};

export const IS_SOURCE_WEBVIEW_FRAGMENT = 'IS_SOURCE_WEBVIEW_FRAGMENT';

export const DELIVERY_FEE_CART_STRIPS_DATA = {
  MINIMUM_ORDER_VALUE: {
    description: 'ऑर्डर ₹200 से ऊपर का होना चाहिए',
    name: 'MINIMUM_ORDER_VALUE',
    color: 'rgba(225, 25, 0, 1)',
    backgroundColor: 'rgba(255, 218, 214, 1)',
  },
  DELIVERY_CHARGE_INCLUDED: {
    description: '₹200 से नीचे ₹49 डिलीवरी चार्ज है!',
    name: 'DELIVERY_CHARGE_INCLUDED',
    color: 'rgba(225, 25, 0, 1)',
    backgroundColor: 'rgba(255, 218, 214, 1)',
  },
  FREE_DELIVERY: {
    description: 'आपको FREE डिलीवरी मिलती है!',
    name: 'FREE_DELIVERY',
    color: 'rgba(3, 141, 99, 1)',
    backgroundColor: 'rgba(211, 244, 234, 1)',
  },
  FREE_DELIVERY_DELIVERY_CHARGE_EXCLUDED: {
    description: 'आपको FREE डिलीवरी & ₹49 की छूट मिलती है!',
    name: 'FREE_DELIVERY_DELIVERY_CHARGE_EXCLUDED',
    color: 'rgba(3, 141, 99, 1)',
    backgroundColor: 'rgba(211, 244, 234, 1)',
  },
  DELIVERY_CHARGE_98_INCLUDED: {
    description: '₹98 डिलीवरी चार्ज है!',
    name: 'DELIVERY_CHARGE_98_INCLUDED',
    color: 'rgba(225, 25, 0, 1)',
    backgroundColor: 'rgba(255, 218, 214, 1)',
  },
};

export const DELIVERY_FEE_INCREMENT_VARIANTS = {
  BLOCKING_ORDER_LESS_THAN_MINIMUM_ADV: 'BLOCKING_ORDER_LESS_THAN_MINIMUM_ADV',
  CHARGING_DELIVERY_ON_ORDERS_LESS_THAN_ADV:
    'CHARGING_DELIVERY_ON_ORDERS_LESS_THAN_ADV',
};

export const DELIVERY_FEE_AUDIO_KEYNAME = 'DELIVERY_FEE_AUDIO_KEYNAME';
export const CART_VIEWED_SESSION_COUNTER = 'CART_VIEWED_SESSION_COUNTER';
export const PLACE_ORDER_CLICKED_SESSION_COUNTER =
  'PLACE_ORDER_CLICKED_SESSION_COUNTER';
export const ORDER_NOT_ALLOWED_FOR_ORDER_VALUE_LESS_THAN_ADV =
  'ORDER_NOT_ALLOWED_FOR_ORDER_VALUE_LESS_THAN_ADV';
export const FROM_ARD_SESSION_KEYNAME = 'from_ard_session_keyname';
export const VIEW_CART_CLICKED_SESSION_COUNTER =
  'VIEW_CART_CLICKED_SESSION_COUNTER';

export const RECENT_SEARCHES_KEY = 'RECENT_SEARCHES_KEY';
export const RECENT_SEARCHES_KEY_MAX_LIMIT = 4;
export const POPULAR_SEARCHES_KEY_MAX_LIMIT = 10;

export const XO_TOKEN = 'xo_token';

export const CANCEL_TOKEN_MESSAGE = 'Operation canceled due to new request.';

export const SEARCH_INFRA_TYPE = {
  ALGOLIA: 'ALGOLIA',
  ELASTIC_SEARCH: 'ELASTIC_SEARCH',
};
export const FOUR_PRODUCT_HIGHLIGHER_SHIMMER_SHOWN_KEY =
  'four_rupee_highlighter_shimmer_shown';

export const RELEVANCY_WIDGET_COLOR_SET = 'relevancy_widget_color_set';

export const SUGGESTION_LIST_IN_SEARCH_PLACEHOLDER = [
  'Peanut',
  'Detergent',
  'Jeera',
  'Kaju',
  'Shampoo',
  'Besan',
];

export const POPULAR_SEARCHES_SAMPLE = [
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/4vtfus18j99qggjxskvox5pjddm51dyt.webp',
    title: 'Kaju',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/spqh1jat79buucscpwn1ojae4b4fcebz.webp',
    title: 'Rice',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/0syp2n04ainhdun6ika4dxjxz80dq2jg.webp',
    title: 'Ghee',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/ncebswqith64ytplqfknxu9jb8rc5chw.webp',
    title: 'Jeera',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/l1z0djh1tlt4azzmmmeynkz9n9ognkbe.webp',
    title: 'Peanut',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/511669642940784.webp',
    title: 'Tea',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/iy6djrhfarjujvditve7cgnd5xavkc6k.webp',
    title: 'Detergent',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/ozn9u6e6vbyb0q0yphxlc0g1jk1tc5eh.webp',
    title: 'Makhana',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/gz90ust9yicff3uekw791axastr2oyy1.webp',
    title: 'Badam',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/551669183942089.webp',
    title: 'Soap',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/m78o0yxt5w138h7quctqxtkquyh3rvua.webp',
    title: 'Sabudana',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/qh57v16c75et9vcecwj8qau7d5a1c6ma.webp',
    title: 'Shampoo',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/a3ee23c90a04cabf3eaceadd40376f6e.webp',
    title: 'Anjeer',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/b21a70dbe8d7f30e67544071ca544701.webp',
    title: 'Rava',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/dbc18f4a1d6bff643fd6b65405b13b69.webp',
    title: 'Biscuits',
  },
  {
    image_url:
      'https://images.meesho.com/superstore/images/products/3qbg7zdukvpot1c1dgqi8j7vm84mvktj.webp',
    title: 'Poha',
  },
];

export const relevancyWidgetBackgroundCombo = {
  initial: {
    outer: `linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1))`,
    inner: `linear-gradient(180deg, rgba(238, 249, 255, 1), rgba(255, 255, 255, 1))`,
  },
  final: {
    outer: `linear-gradient(180deg, rgba(225, 244, 255, 1), rgba(225, 244, 255, 1))`,
    inner: `linear-gradient(180deg, rgba(255, 255, 255, 1), rgba(225, 244, 255, 1))`,
  },
};

export const CATALOG_TYPE_VARIANTS = {
  FEED_VIEW_USER_NX2_LONG: 'FEED_VIEW_USER_NX2_LONG',
  FEED_VIEW_USER_NX2_SMALL: 'FEED_VIEW_USER_NX2_SMALL',
};

export const HOME_PAGE_COMPONENT_TYPE = {
  X_RUPEE_DEAL: 'X_RUPEE_DEAL',
  CATEGORY_WIDGET_DEFAULT: 'CATEGORY_WIDGET_DEFAULT',
  NDD_BANNER_ENHANCED: 'NDD_BANNER_ENHANCED',
  BANNER_GROUP: 'BANNER_GROUP',
  PDM: 'PDM',
  CATEGORY_WIDGET_GROUP: 'CATEGORY_WIDGET_GROUP',
  WIDGET_GROUP: 'WIDGET_GROUP',
  ORDER_RATING_WIDGET: 'ORDER_RATING_WIDGET',
  MONTHLY_BASKET_WIDGET: 'MONTHLY_BASKET_WIDGET',
  PERSONALISED_PRODUCT_WIDGET: 'PERSONALISED_PRODUCT_WIDGET',
  SALE_BANNER: 'SALE_BANNER',
  REFERRAL_BANNER: 'REFERRAL_BANNER',
  PRICE_PERCEPTION_BANNER: 'PRICE_PERCEPTION_BANNER',
  ONE_PRODUCT_HIGHLIGHTER: 'ONE_PRODUCT_HIGHLIGHTER',
  FOUR_PRODUCT_HIGHLIGHTER: 'FOUR_PRODUCT_HIGHLIGHTER',
  FEED: 'FEED',
  REVIEW_PROMPT_WIDGET: 'REVIEW_PROMPT_WIDGET',
};
export const UNAUTHORIZED_STATUS = 401;
